<template>
  <div
    class="view scrollable announcements-list-view"
    id="announcements"
    :class="theme"
  >
    <h1 class="view__heading">Ogłoszenia</h1>
    <RefreshComponent :top="refreshPosition" :loading="loading" />
    <transition-group name="fadeBlur">
      <div class="view__loader" v-if="loading" key="loader">
        <LoaderComponent :display="loading" />
      </div>
      <div class="announcements-list" v-else key="content">
        <AnnouncementComponent
          v-for="announcement in announcements"
          :data="announcement"
          :key="announcement.id"
          @click="openAnnouncement(announcement.id)"
        />
        <InlineNotification
          icon="fa-regular fa-newspaper"
          text="Ups! Nie ma jeszcze żadanych ogłoszeń."
          v-if="!announcements.length"
          action="notifications"
          @reload="getNotifications(page, announcementsPerPage)"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data.js";
import AnnouncementComponent from "@/components/AnnouncementComponent.vue";
import InlineNotification from "@/components/InlineNotification/component.vue";
import LoaderComponent from "@/components/Loader/component.vue";
import RefreshComponent from "@/components/Refresh/component.vue";

export default {
  name: "AnnouncementsListView",
  components: {
    RefreshComponent,
    LoaderComponent,
    InlineNotification,
    AnnouncementComponent,
  },
  data() {
    return {
      page: 1,
      announcementsPerPage: 10,
      refreshPosition: -100,
      touchStart: false,
      loading: false,
    };
  },
  computed: {
    theme() {
      return Theme.get();
    },
    articles() {
      return this.$store.state.articles.data;
    },
    announcements() {
      return this.$store.getters["notifications/notificationsList"];
    },
  },
  methods: {
    openAnnouncement(id) {
      this.$router.push(`announcements/${id}`);
    },
    getNotifications() {
      this.loading = true;

      Data.getNotifications(this.$route.params.id)
        .then((res) => {
          this.$store.dispatch("notifications/setNotificationsList", res);
        })
        .catch((err) => {
          console.error("Error!", err);
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<style lang="scss" scoped>
.announcements-list-view {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  padding: var(--gap-medium);

  &.dark {
    color: var(--color-light);
  }

  .view__heading {
    padding: var(--gap-medium) 0;
    color: var(--color-dark);
  }

  .announcements-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: var(--gap-medium);
  }
}
</style>
