export default {
  namespaced: true,
  state: {
    notificationsList: [],
  },
  getters: {
    notificationsList: (state) =>
      state.notificationsList
        ? state.notificationsList.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )
        : [],
  },
  mutations: {
    CLEAR_NOTIFICATIONS_LIST(state) {
      while (state.notificationsList.length) {
        state.notificationsList.pop();
      }
    },
    SET_NOTIFICATIONS_LIST(state, payload) {
      state.notificationsList = payload;
    },
  },
  actions: {
    clearNotificationsList({ commit }) {
      commit("CLEAR_NOTIFICATIONS_LIST");
    },
    setNotificationsList({ commit, state }, payload) {
      commit("CLEAR_NOTIFICATIONS_LIST");
      commit("SET_NOTIFICATIONS_LIST", payload);
    },
  },
};
