<template>
  <div class="newsComponent" :class="theme">
    <div class="newsComponent__image">
      <img
        :src="
          data.photo_path
            ? data.photo_path_full
            : '/img/default/' + getSettings().appDefaultNewsImage
        "
        alt="newsImage"
      />
      <div class="newsComponent__date">{{ formatDate(data.start_stamp) }}</div>
    </div>
    <div class="newsComponent__text">
      <div class="newsComponent__title">{{ formatTitle(data.title) }}</div>
      <div class="newsComponent__short">
        <span>Zobacz</span>
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "NewsComponent",
  props: {
    data: Object,
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    formatTitle(input) {
      const words = input.split(" ");
      if (words.length > 8) {
        return words.slice(0, 8).join(" ") + "...";
      }
      return input;
    },
    getSettings() {
      return this.$store.state.settings;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
