<template>
  <div
    class="notificationComponent"
    @click="$emit('edit', notification)"
    :class="theme"
  >
    <div class="notificationComponent__text">
      <font-awesome-icon icon="fa-regular fa-bell" />
      <span v-html="notificationText()"></span>
    </div>
    <div class="notificationComponent__button" @click.stop="setConfirm">
      <transition name="slideRight">
        <font-awesome-icon icon="fa-regular fa-trash-can" v-if="!confirm" />
        <div
          class="notificationComponent__button--text"
          v-else
          @click.stop="deleteNotification"
        >
          usuń
        </div>
      </transition>
    </div>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="3"
        size="large"
        position="top"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import Notifications from "@/assets/js/Notifications.js";
import PopupComponent from "@/components/Popup/component.vue";

export default {
  name: "NotificationComponent",
  props: ["notification"],
  components: {
    PopupComponent,
  },
  data() {
    return {
      popupData: {
        display: false,
        color: "",
        text: "",
      },
      confirm: false,
    };
  },
  methods: {
    setConfirm() {
      this.confirm = true;
      setTimeout(() => {
        this.confirm = false;
      }, 2000);
    },
    notificationText() {
      const termName = this.getSettings().notificationTerms.find(
        (item) => item.value === this.notification.term
      ).name;
      const hourText =
        (this.notification.hour < 10
          ? "0" + this.notification.hour
          : this.notification.hour) + ":00";
      return "<strong>" + hourText + "</strong> | " + termName;
    },
    async deleteNotification() {
      if (!(await Notifications.delete(this.notification.id))) {
        this.showPopup("error", "Nie udało się usunąć powiadomienia!");
      }
    },
    getSettings() {
      return this.$store.state.settings;
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
