<template>
  <SplashScreen @loaded="show" />
  <TopBar :deep="backDisplay" v-if="display" @toggleWcagMenu="toggleWcagMenu" />
  <transition name="slide">
    <WcagMenu v-show="wcag" />
  </transition>
  <transition name="fade">
    <PopupComponent
      v-if="!$store.state.settings.appOnline"
      color="secondary"
      size="small"
      text="Jesteś w trybie offline! Niektóre funkcje mogą nie działać."
      position="bottom"
    />
  </transition>
  <router-view v-slot="{ Component }" v-if="display">
    <transition name="slide">
      <component :is="Component" />
    </transition>
  </router-view>
  <MainMenu v-if="display" />
  <transition name="fadeBlurScale">
    <ModalComponent
      v-if="modal"
      @update="updateNotifications"
      title="Aktualizacja powiadomień"
      text="Zaktualizowano kalendarz dla rejonów, w których masz ustawione powiadomienia."
      :backdrop-close="false"
      :close-button="false"
      :buttons="modalButtons"
    />
  </transition>
  <transition name="fadeBlurScale">
    <ModalComponent
        v-if="permissionModalOpened"
        title="Brak uprawnień"
        text="Nie masz wystarczających uprawnień do otrzymywania powiadomień.
                          Aby skonfigurować ustawienia powiadomień,
                          kliknij przycisk ustawienia i zezwól w nich na powiadomienia."
        :backdrop-close="false"
        :close-button="false"
        :buttons="permissionModalButtons"
        @close="closePermissionModal"
        @settings="openSettings"
    />
  </transition>
  <transition name="fadeBlurScale">
    <ModalComponent
        v-if="deletedNotificationsModalOpened"
        title="Usunięte powiadomienia"
        text="Nastąpiła zmiana w obszarze rejonów, w związku z tym niektóre z twoich powiadomień zostały usunięte."
        :backdrop-close="false"
        :close-button="false"
        :buttons="deletedNotificationsModalButtons"
        @close="closeDeletedNotificationsModal"
    />
  </transition>
</template>

<script>
import TopBar from "@/components/TopBar/component.vue";
import MainMenu from "@/components/MainMenu/component.vue";
import SplashScreen from "@/views/SplashScreen/view.vue";
import WcagMenu from "@/components/WcagMenu/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import ModalComponent from "@/components/Modal/component.vue";
import Online from "@/assets/js/Online.js";
import Data from "@/assets/js/Data.js";
import Theme from "@/assets/js/Theme.js";
import Notifications from "@/assets/js/Notifications.js";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
export default {
  components: {
    TopBar,
    MainMenu,
    SplashScreen,
    WcagMenu,
    PopupComponent,
    ModalComponent,
  },
  data() {
    return {
      display: false,
      wcag: false,
      checks: 0,
      modal: false,
      swipe: false,
      modalButtons: [
        {
          text: "OK",
          action: "update",
        },
      ],
      permissionModalButtons: [
        {
          text: "Zamknij",
          action: "close",
        },
        {
          text: "Ustawienia",
          action: "settings",
        },
      ],
      deletedNotificationsModalButtons: [
        {
          text: "OK",
          action: "close",
        },
      ],
    };
  },
  methods: {
    show(loaded) {
      this.$store.commit("changeSettings", { prop: "appOnline", val: loaded });
      this.display = true;

      this.showNotificationIfDistrictsChanged();

      document.addEventListener(
        "touchstart",
        (e) => {
          if (e.changedTouches.length !== 1) return;
          if (e.changedTouches[0].clientX < 10)
            this.swipe = e.changedTouches[0].clientX;
        },
        { passive: true }
      );
      document.addEventListener(
        "touchend",
        (e) => {
          if (e.changedTouches.length !== 1) return;
          const end = e.changedTouches[0].clientX;
          if (this.swipe && this.swipe + 100 < end) {
            window.history.back();
            this.swipe = false;
          }
        },
        { passive: true }
      );
      window.addEventListener("visibilitychange", () => {
        if (
          document.visibilityState === "visible" &&
          this.$store.state.userSettings.appTheme === "default"
        ) {
          this.$store.commit("changeUserSettings", {
            prop: "appTheme",
            val: this.$store.state.userSettings.tmpTheme,
          });
          setTimeout(() => {
            Theme.set("default");
          }, 100);
        }
      });
      Notifications.reload();
      Notifications.setPushState(
        "news",
        this.$store.state.settings.pushNotificationsAvailable
      );
      clearInterval(window.refresh);
      window.refresh = setInterval(async () => {
        const online = await Online.Check();
        this.$store.commit("changeSettings", {
          prop: "appOnline",
          val: online,
        });
        this.checks++;
        if (online && this.checks % 10 === 0) {
          await Data.load();
          // this.modal = await Notifications.checkNew();
        }
      }, 30000);
    },
    showNotificationIfDistrictsChanged() {
      if (
          this.districts.last_edited &&
          new Date(this.districts.last_edited) >
          new Date(this.$store.state.userSettings.districtsLastEdited)
      ) {
        this.modal = true;
        this.$store.commit("setDistrictsLastEdited", {
          districtsLastEdited: this.districts.last_edited,
        });
      }
    },
    toggleWcagMenu() {
      this.wcag = !this.wcag;
    },
    updateNotifications() {
      // Notifications.reload();
      this.modal = false;
    },
    closePermissionModal() {
      this.$store.commit("modals/CLOSE_PERMISSION_MODAL");
    },
    closeDeletedNotificationsModal() {
      this.$store.commit("modals/CLOSE_DELETED_NOTIFICATIONS_MODAL");
    },
    async openSettings() {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
      this.$store.commit("modals/CLOSE_PERMISSION_MODAL");
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
    backDisplay() {
      return this.$route.path.split("/").length > 2;
    },
    districts() {
      return this.$store.state.districts;
    },
    permissionModalOpened() {
      return this.$store.getters["modals/isPermissionsModalOpened"];
    },
    deletedNotificationsModalOpened() {
      return this.$store.getters["modals/isDeletedNotificationsModalOpened"];
    },
  },
  created() {
    if (this.permissionModalOpened) {
      this.closePermissionModal();
    }
    if (this.deletedNotificationsModalOpened) {
      this.closeDeletedNotificationsModal();
    }
  },
};
</script>
