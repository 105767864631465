<template>
  <div class="view" :class="theme">
    <h1 class="view__heading">Ustawienia</h1>
    <div class="settings" :class="theme">
      <transition-group name="slideLeft">
        <div class="settings__list" v-if="!$route.params.setting" key="list">
          <div
            class="settings__position"
            @click="$router.push('/settings/theme')"
          >
            <div class="settings__text">
              <div class="settings__name">Motyw aplikacji</div>
              <div class="settings__value">{{ getThemeName(themeName) }}</div>
            </div>
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </div>
          <div
            class="settings__position"
            @click="$router.push('/settings/notifications')"
          >
            <div class="settings__text">
              <div class="settings__name">Powiadomienia</div>
              <div class="settings__value">{{ getNotificationsState }}</div>
            </div>
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </div>
          <div
            class="settings__position"
            @click="$router.push('/settings/informations')"
          >
            <div class="settings__text">
              <div class="settings__name">Informacje o aplikacji</div>
              <div class="settings__value">{{ getAppName() }}</div>
            </div>
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </div>
        </div>
        <ThemeView v-if="$route.params.setting === 'theme'" key="theme" />
        <NotificationsView
          v-if="$route.params.setting === 'notifications'"
          key="notifications"
        />
        <InformationsView
          v-if="$route.params.setting === 'informations'"
          key="informations"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import ThemeView from "@/views/Theme/view.vue";
import NotificationsView from "@/views/Notifications/view.vue";
import InformationsView from "@/views/Informations/view.vue";
import Notifications from "@/assets/js/Notifications.js";

export default {
  name: "SettingsView",
  components: {
    ThemeView,
    NotificationsView,
    InformationsView,
  },
  methods: {
    getThemeName(theme) {
      if (this.getSettings().themes.find((item) => item.value === theme))
        return this.getSettings().themes.find((item) => item.value === theme)
          .name;
    },
    getSettings() {
      return this.$store.state.settings;
    },
    getUserSettings() {
      return this.$store.state.userSettings;
    },
    getNotifications() {
      return Notifications.getAllNotifications();
    },
    getAppName() {
      return this.getSettings().appNameStore;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
    themeName() {
      return this.getUserSettings().appTheme;
    },
    getNotificationsState() {
      if (
        !this.getNotifications().news &&
        !this.getNotifications().waste.length
      )
        return "wyłączone";
      return "włączone";
    },
  },
};
</script>
