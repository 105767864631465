<template>
  <header class="topBar" :class="theme">
    <div class="topBar__back">
      <transition name="fade">
        <div class="topBar__back" @click="goBack()" v-if="deep">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </div>
      </transition>
    </div>

    <div class="topBar__logo">
      <img
        alt="logo"
        :src="'/img/logo/' + getSettings().appLogo"
        class="topBar__img"
      />
      <div class="topBar__title">
        <div>{{ getSettings().appUpTitle }}</div>
        <div>{{ getSettings().appTitle }}</div>
      </div>
    </div>

    <div class="topBar__menu-link">
      <img
        src="/img/logo/eu.svg"
        v-if="eu"
        @click="$router.push('/waste/eu')"
      />
      <font-awesome-icon
        v-if="wcag"
        icon="fa-solid fa-universal-access"
        @click="toggleWcagMenu"
      />
    </div>
  </header>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "TopBar",
  props: {
    deep: Boolean,
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
    toggleWcagMenu() {
      this.$emit("toggleWcagMenu");
    },
    goBack() {
      const path = this.$route.path.split("/");
      if (path.length > 2) {
        path.pop();
        if (path.includes("news")) this.$router.go(-1);
        // exception for preserving scroll state in the news section
        else this.$router.push(path.join("/"));
      }
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
    wcag() {
      return this.getSettings().appExtraMenu.wcag;
    },
    eu() {
      return this.getSettings().appExtraMenu.eu;
    },
  },
};
</script>
