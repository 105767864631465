<template>
  <div class="reportView" :class="theme">
    <h3>Zgłoś sprawę</h3>
    <SelectList
      label="Temat*"
      :expandable="true"
      :items="subjects"
      v-model="formData.selectedSubject"
    />
    <div class="reportView__additionalInfo" v-if="selectedInfo">
      {{ selectedInfo }}
    </div>
    <SelectList
      label="Rodzaj odpadów"
      :expandable="true"
      :items="fractions()"
      v-model="formData.fraction"
      v-if="formData.selectedSubject === 'uncollected'"
    />
    <InputField
      label="Adres email*"
      placeholder="wpisz"
      v-model="formData.email"
      @focus="focus('email')"
      @blur="emailFocus = false"
      :class="emailFocus && 'fullscreen'"
      @close="emailFocus = false"
      :focus="emailFocus"
    />
    <InputField
      label="Numer telefonu"
      placeholder="wpisz"
      v-model="formData.phone"
      @focus="focus('phone')"
      @blur="phoneFocus = false"
      :class="phoneFocus && 'fullscreen'"
      @close="phoneFocus = false"
      :focus="phoneFocus"
    />
    <InputField
      label="Adres*"
      placeholder="wpisz"
      v-model="formData.address"
      @focus="focus('address')"
      @blur="addressFocus = false"
      :class="addressFocus && 'fullscreen'"
      @close="addressFocus = false"
      :focus="addressFocus"
    />
    <InputField
      label="Data zdarzenia"
      :readonly="true"
      action="datepicker"
      v-model="formData.date"
      @inputClick="inputClick"
    />
    <InputField
      label="Wiadomość*"
      :multiline="true"
      placeholder="treść wiadomości"
      v-model="formData.message"
      @focus="focus('message')"
      @blur="messageFocus = false"
      :class="messageFocus && 'fullscreen'"
      @close="messageFocus = false"
      :focus="messageFocus"
    />
    <CheckboxComponent v-model="formData.dataProcessing">
      <template #label>
        *Oświadczam, że zapoznałem się i akceptuję politykę prywatności.
        <a
          v-if="link.privacy_policy"
          :href="link.privacy_policy"
          target="_blank"
          @click.stop
        >
          ( Zobacz )
        </a>
      </template>
    </CheckboxComponent>
    <ButtonComponent
      text="wyślij"
      icon="fa-solid fa-paper-plane"
      @click="send"
      :loading="sending"
    />
    <transition name="fadeBlurScale">
      <CalendarComponent
        v-model:display="datepicker"
        v-model:date="formData.date"
        :datepicker="true"
      />
    </transition>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="3"
        size="large"
        position="top"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import InputField from "@/components/InputField/component.vue";
import SelectList from "@/components/SelectList/component.vue";
import ButtonComponent from "@/components/Button/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import CheckboxComponent from "@/components/Checkbox/component.vue";
import CalendarComponent from "@/components/Calendar/component.vue";
import DB from "@/assets/js/DB.js";
import { Keyboard } from "@capacitor/keyboard";

export default {
  name: "ReportView",
  data() {
    return {
      subjects: [
        { name: "wybierz z listy", value: false },
        { name: "nieodebrane odpady", value: "uncollected" },
      ],
      formData: {
        selectedSubject: false,
        fraction: this.fractions()[0].value,
        email: "",
        phone: "",
        address: "",
        date: new Date().toLocaleDateString(),
        message: "",
        dataProcessing: false,
      },
      popupData: {
        display: false,
        color: "",
        text: "",
      },
      datepicker: false,
      emailFocus: false,
      phoneFocus: false,
      addressFocus: false,
      messageFocus: false,
      sending: false,
    };
  },
  components: {
    InputField,
    SelectList,
    ButtonComponent,
    PopupComponent,
    CheckboxComponent,
    CalendarComponent,
  },
  methods: {
    async send() {
      if (this.validateForm() && !this.sending) {
        const data = {
          subject: this.subjects.find(
            (item) => item.value === this.formData.selectedSubject
          ).name,
          sender: this.formData.email.trim(),
          body: `<div style="font-size: 16px; font-weight: bold;">ZGŁOSZENIE Z APLIKACJI:</div><p><strong>Adres:</strong> <br>${
            this.formData.address
          }</p>
            ${
              this.formData.phone
                ? `<p><strong>Numer telefonu:</strong> <br>${this.formData.phone}</p>`
                : ""
            } ${
            this.formData.selectedSubject === "uncollected"
              ? `<p><strong>Temat:</strong></p><p>Nieodebrane odpady: ${
                  this.fractions().find(
                    (item) => item.value === this.formData.fraction
                  ).name
                }</p>`
              : ""
          }<p><strong>Data:</strong> <br>${
            this.formData.date
          }</p><p><strong>Wiadomość:</strong><br>${
            this.formData.message
          }</p><hr>`,
        };
        try {
          this.sending = true;
          const response = await DB.post("/message", data);
          if (response && response.status === "success") {
            this.showPopup("success", "Wiadomość została wysłana!");
            this.sending = false;
            this.formData = {
              selectedSubject: false,
              email: "",
              phone: "",
              address: "",
              date: new Date().toLocaleDateString(),
              message: "",
            };
          } else if (
            response.status === "error" &&
            response.message === "mailer error"
          ) {
            this.showPopup(
              "error",
              "Nie udało się wysłać wiadomości z powodu błędu serwera!"
            );
            this.sending = false;
          } else if (
            response.status === "error" &&
            response.message === "bad address"
          ) {
            this.showPopup("warning", "Musisz wpisać poprawny adres email!");
            this.sending = false;
          } else if (
            response.status === "error" &&
            response.message === "limit reached"
          ) {
            this.showPopup(
              "error",
              "Przekroczono limit wysłanych zgłoszeń! Spróbuj ponownie później."
            );
            this.sending = false;
          } else {
            this.showPopup("error", "Wystąpił błąd! Spróbuj ponownie później.");
            this.sending = false;
          }
        } catch (e) {
          this.showPopup("error", "Wystąpił błąd! Spróbuj ponownie później.");
          this.sending = false;
        }
      }
    },
    validateForm() {
      if (!this.formData.selectedSubject) {
        this.showPopup("warning", "Musisz wybrać temat zgłoszenia!");
        return false;
      } else if (
        !this.formData.email.trim() ||
        !this.validateEmail(this.formData.email.trim())
      ) {
        this.showPopup("warning", "Musisz wpisać poprawny adres email!");
        return false;
      } else if (
        this.formData.phone &&
        !this.validatePhoneNumber(this.formData.phone)
      ) {
        this.showPopup("warning", "Musisz wpisać poprawny numer telefonu!");
        return false;
      } else if (!this.formData.address) {
        this.showPopup("warning", "Musisz wpisać adres!");
        return false;
      } else if (!this.formData.message) {
        this.showPopup("warning", "Wpisz treść wiadomości!");
        return false;
      } else if (!this.formData.dataProcessing) {
        this.showPopup(
          "warning",
          "Musisz wyrazić zgodę na przetwarzanie danych!"
        );
        return false;
      }
      return true;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validatePhoneNumber(phone) {
      return String(phone)
        .toLowerCase()
        .match(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/);
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
    fractions() {
      const fractions = [];
      this.$store.state.fractions.data.forEach((fraction) => {
        fractions.push({ name: fraction.name, value: fraction.id });
      });
      return fractions;
    },
    inputClick(action) {
      if (action === "datepicker") this.datepicker = !this.datepicker;
    },
    focus(elem) {
      if (elem === "email") this.emailFocus = true;
      if (elem === "phone") this.phoneFocus = true;
      if (elem === "address") this.addressFocus = true;
      if (elem === "message") this.messageFocus = true;
      Keyboard.addListener("keyboardDidShow", () => {
        window.scrollTo(0, 0);
      });
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
    selectedInfo() {
      return this.subjects.find(
        (item) => item.value === this.formData.selectedSubject
      ).info;
    },
    link() {
      return this.$store.state.links;
    },
  },
};
</script>
