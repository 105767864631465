<template>
  <div class="selectList" :class="theme">
    <div class="selectList__label" v-if="label">{{ label }}</div>
    <div class="selectList__field">
      <div
        class="selectList__value"
        tabindex="-1"
        v-if="expandable"
        @click="expand($event)"
      >
        <span>{{ removeColon(items[selectedIndex].name) }}</span>
        <div class="selectList__icon" :class="expanded && 'rotate'">
          <font-awesome-icon icon="fa-solid fa-chevron-down" />
        </div>
      </div>
      <transition name="fadeFast">
        <div
          class="selectList__list"
          :class="{ abs: expandable }"
          v-if="!expandable || expanded"
        >
          <div
            class="selectList__position"
            v-for="(item, index) in items"
            :key="index"
            @click="select(index)"
          >
            <div class="selectList__item">
              <span :class="{ 'bold-text': isSelectedOptionTextBold }">
                {{ item.name }}
              </span>
              {{ item.subName }}
            </div>
            <font-awesome-icon
              icon="fa-solid fa-check"
              v-if="index === selectedIndex"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "SelectList",
  props: [
    "label",
    "items",
    "expandable",
    "modelValue",
    "selected",
    "isSelectedOptionTextBold",
  ],
  data() {
    return {
      selectedIndex:
        this.searchItem(this.selected) || this.searchItem(this.modelValue) || 0,
      expanded: false,
    };
  },
  methods: {
    expand(event) {
      this.expanded = !this.expanded;
      const focusable = event.target.classList.contains("selectList__value")
        ? event.target
        : event.target.closest(".selectList__value");
      focusable.focus();
      focusable.onblur = () => {
        this.expanded = false;
      };
    },
    select(index) {
      this.expanded = false;
      this.selectedIndex = index;
      this.$emit("update:modelValue", this.items[this.selectedIndex].value);
      this.$emit("select", this.items[this.selectedIndex].value);
    },
    searchItem(value) {
      const index = this.items.findIndex((item) => item.value === value);
      return index === -1 ? false : index;
    },
    removeColon(text) {
      return text.replace(/:\s*$/, "");
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
  watch: {
    modelValue() {
      this.selectedIndex =
        this.searchItem(this.selected) || this.searchItem(this.modelValue) || 0;
    },
  },
};
</script>
