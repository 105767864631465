<template>
  <div class="input-field" :class="theme">
    <label v-if="label" for="input" class="input-field__label">{{
      label
    }}</label>
    <div class="input-field__field">
      <input
        type="text"
        :placeholder="placeholder"
        class="input-field__input"
        :readonly="readonly ? readonly : null"
        :disabled="disabled ? disabled : null"
        @click.stop="makeAction"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        v-if="!multiline"
      />
      <textarea
        :placeholder="placeholder"
        class="input-field__textarea"
        :readonly="readonly ? readonly : null"
        :disabled="disabled ? disabled : null"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @click.stop=""
        v-else
      ></textarea>
      <font-awesome-icon icon="fa-solid fa-chevron-right" v-if="action" />
      <div v-if="focus" class="input-field__close" @click="$emit('close')">
        <font-awesome-icon icon="fa-solid fa-check" />
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "SearchField",
  props: [
    "label",
    "placeholder",
    "readonly",
    "disabled",
    "multiline",
    "action",
    "modelValue",
    "focus",
  ],
  methods: {
    makeAction() {
      if (this.action) this.$emit("inputClick", this.action);
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
