<template>
  <div class="view" :class="theme">
    <h1 class="view__heading">
      {{ activeSection ? activeSection.title : "Pulpit" }}
    </h1>
    <transition-group name="slideLeft">
      <div class="waste" v-if="!$route.params.section" key="sections">
        <WasteSection
          v-for="(section, key) in sections"
          :key="key"
          :title="section.title"
          :description="section.description"
          :route="section.route"
        />
      </div>
      <WastePointView v-if="$route.params.section === 'point'" key="point" />
      <FractionsView
        v-if="$route.params.section === 'fractions'"
        key="fractions"
      />
      <ReportView v-if="$route.params.section === 'report'" key="report" />
      <ScheduleView
        v-if="$route.params.section === 'schedule'"
        key="schedule"
      />
      <DocumentsView
        v-if="$route.params.section === 'documents'"
        key="documents"
      >
      </DocumentsView>
      <MyScheduleView
        v-if="$route.params.section === 'myschedule'"
        key="myschedule"
      />
    </transition-group>
  </div>
</template>

<script>
import "./style.scss";
import WasteSection from "@/components/WasteSection/component.vue";
import WastePointView from "@/views/WastePoint/view.vue";
import FractionsView from "@/views/Fractions/view.vue";
import ReportView from "@/views/Report/view.vue";
import ScheduleView from "@/views/Schedule/view.vue";
import MyScheduleView from "@/views/MySchedule/view.vue";
import Theme from "@/assets/js/Theme.js";
import DocumentsView from "@/views/Documents/view.vue";

export default {
  name: "WasteView",
  data() {
    return {
      activeSection: null,
      sections: [
        {
          title: "Twój harmonogram",
          description: "Sprawdź harmonogram dostępny dla Twojej lokalizacji",
          route: "/waste/myschedule",
        },
        {
          title: "Harmonogram gminy",
          description: "Sprawdź ogólny harmonogram gminy",
          route: "/waste/schedule",
        },
        {
          title: "Zgłoszenia",
          description: "Chcesz zgłosić jakąś sprawę?",
          route: "/waste/report",
        },
        {
          title: "Jak sortować odpady",
          description: "Dowiedz się jak poprawnie sortować odpady",
          route: "/waste/fractions",
        },
        {
          title: "Dokumenty",
          description: "Dokumenty do pobrania dla mieszkańców",
          route: "/waste/documents",
        },
        {
          title: "PSZOK",
          description: "Punkt Selektywnej Zbiórki Odpadów Komunalnych",
          route: "/waste/point",
        },
        {
          title: "Kontakt",
          description: "Potrzebujesz skontaktować się z gminą?",
          route: "/waste/contact",
        },
      ],
    };
  },
  components: {
    WasteSection,
    WastePointView,
    FractionsView,
    ReportView,
    ScheduleView,
    MyScheduleView,
    DocumentsView,
  },
  computed: {
    theme() {
      return Theme.get();
    },
    route() {
      return this.$route;
    },
  },
  methods: {
    getSectionTitle(sectionKey) {
      return this.sections.find(
        (section) => section.route.split("/").pop() === sectionKey
      );
    },
    setActiveSection(route) {
      if (route?.params?.section) {
        this.activeSection = this.getSectionTitle(route.params.section);
      } else {
        this.activeSection = null;
      }
    },
  },
  watch: {
    route(newVal) {
      this.setActiveSection(newVal);
    },
  },
  created() {
    if (this.$route?.params?.section) {
      this.setActiveSection(this.$route);
    }
  },
};
</script>
