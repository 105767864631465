<template>
  <div class="fractionsView" :class="theme">
    <div
      class="fractionsView__fraction"
      v-for="fraction in fractions()"
      :key="fraction.id"
    >
      <div
        class="fractionsView__fraction-color"
        :style="{ 'background-color': fraction.color }"
      ></div>
      <div
        class="fractionsView__fraction-header"
        @click="toggleExpand(fraction.id)"
      >
        <div class="fractionsView__fraction-title">
          <span>{{ fraction.name }}</span>
          {{ fraction.description }}
        </div>
        <font-awesome-icon
          icon="fa-solid fa-chevron-down"
          :class="{ rotated: expandedFraction === fraction.id }"
        />
      </div>
      <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
      >
        <div
          class="fractionsView__fraction-content"
          v-show="expandedFraction === fraction.id"
        >
          <div v-html="fraction.content"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "FractionsView",
  data() {
    return {
      expandedFraction: -1,
    };
  },
  methods: {
    fractions() {
      return this.$store.state.fractions.data;
    },
    toggleExpand(id) {
      if (this.expandedFraction === id) this.expandedFraction = -1;
      else this.expandedFraction = id;
    },
    start(el) {
      el.style.height = 0;
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
