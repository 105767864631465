<template>
  <div class="view scrollable" id="news" :class="theme">
    <h1 class="view__heading">Aktualności</h1>
    <RefreshComponent :top="refreshPosition" :loading="loading" />
    <transition-group name="fadeBlur">
      <div class="view__loader" v-if="loading" key="loader">
        <LoaderComponent :display="loading" />
      </div>
      <div class="allNews" v-else key="content">
        <NewsComponent
          v-for="item in news"
          :data="item"
          :key="item.id"
          @click="loadOneNews(item.id)"
        />
        <InlineNotification
          icon="fa-regular fa-newspaper"
          text="Ups! Nie ma jeszcze żadanych aktualności."
          v-if="!news.length"
          action="news"
          @reload="getNews(page, newsPerPage)"
        />
        <ButtonComponent
          text="Załaduj więcej"
          icon="ellipsis"
          icon-position="left"
          @click="loadNext"
          v-if="news.length > page * newsPerPage"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import "./style.scss";
import NewsComponent from "@/components/News/component.vue";
import ButtonComponent from "@/components/Button/component.vue";
import InlineNotification from "@/components/InlineNotification/component.vue";
import RefreshComponent from "@/components/Refresh/component.vue";
import LoaderComponent from "@/components/Loader/component.vue";
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data.js";

export default {
  name: "NewsView",
  components: {
    NewsComponent,
    ButtonComponent,
    InlineNotification,
    RefreshComponent,
    LoaderComponent,
  },
  data() {
    return {
      page: 1,
      newsPerPage: 10,
      news: [],
      refreshPosition: -100,
      touchStart: false,
      loading: false,
    };
  },
  methods: {
    async refreshData() {
      this.loading = true;
      await Data.load("news");
      this.loading = false;
      this.refreshPosition = 0;
      this.page = 1;
      this.getNews(this.page, this.newsPerPage, false);
    },
    loadOneNews(id) {
      this.$store.pageNews = this.page;
      this.$store.loadedNews = this.news.length;
      this.$router.push(this.$route.path + "/" + id);
    },
    loadNext() {
      this.page++;
      this.getNews(this.page, this.newsPerPage);
    },
    getNews(page, size, append = true) {
      if (this.articles) {
        if (append)
          this.news = [
            ...this.news,
            ...this.articles.slice(size * (page - 1), size * (page - 1) + size),
          ];
        else {
          this.news.length = 0;
          this.news = this.articles.slice(0, size);
        }
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    getSettings() {
      return this.$store.state.settings;
    },
  },
  mounted() {
    if (this.$store.loadedNews) {
      this.getNews(1, this.$store.loadedNews, false);
      this.page = this.$store.pageNews;
    } else this.getNews(this.page, this.newsPerPage, false);
    this.$el.addEventListener("scroll", () => {
      if (
        this.$el.scrollTop + this.$el.clientHeight >=
        this.$el.scrollHeight - 200
      ) {
        this.loadNext();
      }
    });
    this.$el.addEventListener(
      "touchstart",
      (e) => {
        const touch = e.targetTouches[0];
        this.touchStart = touch.screenY;
      },
      { passive: true }
    );
    this.$el.addEventListener(
      "touchmove",
      (e) => {
        if (
          this.touchStart &&
          this.$el.scrollTop <= 0 &&
          !this.$route.params.id
        ) {
          const touch = e.targetTouches[0];
          this.refreshPosition =
            touch.screenY - this.touchStart < 110
              ? touch.screenY - this.touchStart
              : 110;
        }
      },
      { passive: true }
    );
    this.$el.addEventListener(
      "touchend",
      async () => {
        if (this.refreshPosition >= 110) {
          await this.refreshData();
          this.getNews(this.page, this.newsPerPage, false);
        } else {
          this.refreshPosition = -100;
        }
        this.touchStart = false;
      },
      { passive: true }
    );
  },
  computed: {
    theme() {
      return Theme.get();
    },
    articles() {
      return this.$store.state.articles.data;
    },
  },
};
</script>
