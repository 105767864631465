<template>
  <div class="scheduleView" :class="theme">
    <div class="scheduleView__favorite">
    <SelectList
      label="Wybierz rejon"
      :expandable="true"
      :items="districts()"
      :isSelectedOptionTextBold="true"
      v-model="district"
      @select="dates = parseData()"
    />
    <SelectList
      label="Rodzaj odpadów"
      :expandable="true"
      :items="fractions()"
      v-model="fraction"
      @select="dates = parseData()"
    />
    <transition name="fade">
      <div class="scheduleView__controls special" v-if="district">
        <div
          class="scheduleView__control"
          :class="{ checked: getUserSettings().favoriteDistrict === district }"
          @click="modal = true"
        >
          <font-awesome-icon
            :icon="
              getUserSettings().favoriteDistrict !== district
                ? 'fa-regular fa-heart'
                : 'fa-solid fa-heart'
            "
          />
        </div>
        <div
          class="scheduleView__control"
          :class="{ checked: notifications }"
          @click="notificationmodal = true"
        >
          <font-awesome-icon
            :icon="notifications ? 'fa-solid fa-bell' : 'fa-regular fa-bell'"
          />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <CalendarComponent v-model:display="district" :dates="dates" />
    </transition>
    <transition name="fade">
      <div class="scheduleView__additionalInformation">
        Pojemniki i worki należy wystawiać do godz. 6:00 rano w dniu wywozu.
      </div>
    </transition>
    <transition name="fade">
      <div class="scheduleView__district" v-if="district">
        <h3>Rejon:</h3>
        <div v-text="districtDescription(district)"></div>
      </div>
    </transition>
    <transition name="fade">
      <FractionsLegend v-if="district" />
    </transition>
    <transition name="fadeBlurScale">
      <ModalComponent
        v-if="modal"
        @close="modal = false"
        @favorite="setFavorite"
        :title="
          getUserSettings().favoriteDistrict !== district
            ? 'Czy chcesz ustawić ten rejon jako ulubiony?'
            : 'Czy na pewno chcesz wyłączyć ulubiony rejon?'
        "
        :backdrop-close="true"
        :close-button="true"
        :buttons="modalButtons"
      />
    </transition>
    <transition name="fadeBlurScale">
      <NotificationModal
        :district="district"
        @saved="notificationSaved"
        v-if="notificationmodal"
        @close="notificationmodal = false"
        :backdrop-close="true"
        :close-button="true"
        :list="true"
      />
    </transition>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="3"
        size="large"
        position="top"
      />
    </transition>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import SelectList from "@/components/SelectList/component.vue";
import CalendarComponent from "@/components/Calendar/component.vue";
import FractionsLegend from "@/components/FractionsLegend/component.vue";
import ModalComponent from "@/components/Modal/component.vue";
import NotificationModal from "@/components/NotificationModal/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import Notifications from "@/assets/js/Notifications.js";

export default {
  name: "ScheduleView",
  data() {
    return {
      district: this.$route.params.district
        ? parseInt(this.$route.params.district)
        : false,
      fraction: false,
      dates: [],
      modal: false,
      modalButtons: [
        {
          text: "Nie",
          color: "secondary",
          action: "close",
        },
        {
          text: "Tak",
          action: "favorite",
        },
      ],
      notificationmodal: false,
      popupData: {
        display: false,
        color: "",
        text: "",
      },
    };
  },
  components: {
    SelectList,
    CalendarComponent,
    FractionsLegend,
    ModalComponent,
    NotificationModal,
    PopupComponent,
  },
  methods: {
    districts() {
      const districts = [{ name: "wybierz z listy", value: false }];
      this.$store.state.districts.data.forEach((district) => {
        const descriptionPart = district.description
          ? this.formatString(district.description)
          : "";
        const districtName = district.description
          ? district.name + " : "
          : district.name;
        districts.push({
          name: districtName,
          subName: descriptionPart,
          value: district.id,
        });
      });
      return districts;
    },
    districtDescription(id) {
      if (id) {
        return this.$store.state.districts.data.find((item) => item.id === id)
          .description;
      }
    },
    fractions() {
      const fractions = [{ name: "wszystkie", value: false, subName: null }];
      this.$store.state.fractions.data.forEach((fraction) => {
        fractions.push({
          name: fraction.name,
          value: fraction.id,
          subName: null,
        });
      });
      return fractions;
    },
    formatString(input, length) {
      const words = input.split(" ");
      if (words.length > length) {
        return words.slice(0, length).join(" ") + "...";
      }
      return input;
    },
    parseData() {
      let data = [];
      if (!this.district || !this.$store.state.waste.data) return data;
      data = this.$store.state.waste.data.filter(
        (item) => item.id_district === this.district
      );
      if (this.fraction)
        data = data.filter((item) => item.id_fraction === this.fraction);
      return data;
    },
    setFavorite() {
      this.$store.state.userSettings.favoriteDistrict =
        this.getUserSettings().favoriteDistrict !== this.district
          ? this.district
          : false;
      this.$store.dispatch("saveState");
      this.modal = false;
    },
    getActiveNotifications() {
      return Notifications.getActiveNotifications(this.district);
    },
    notificationSaved() {
      this.showPopup("success", "Powiadomienie zostało zapisane.");
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
    getSettings() {
      return this.$store.state.settings;
    },
    getUserSettings() {
      return this.$store.state.userSettings;
    },
  },
  mounted() {
    this.dates = this.parseData();
  },
  computed: {
    theme() {
      return Theme.get();
    },
    notifications() {
      return this.getActiveNotifications().length;
    },
  },
};
</script>
