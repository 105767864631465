<template>
  <a
    :href="href"
    :target="target"
    class="externalLinkButton"
    :class="{
      dark: theme === 'dark',
      lightIcon: !text,
    }"
  >
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span v-if="text">{{ text }}</span>
  </a>
</template>

<script>
import Theme from "@/assets/js/Theme.js";

export default {
  name: "ExternalLinkButton",
  props: {
    href: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    target: {
      type: String,
      default: "_blank",
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>

<style scoped lang="scss">
.externalLinkButton {
  border: none;
  outline: none;
  height: 40px;
  min-width: 40px;
  justify-content: center;
  border-radius: 4px;
  color: var(--color-white);
  font-size: 1em;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  gap: var(--gap-medium);
  padding: 0 var(--gap-medium);
  margin: var(--gap-medium) 0;
  align-self: center;
  flex-direction: row;
  background-color: var(--color-primary);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  transition: all 0.3s;

  &::after {
    content: "";
    background-color: var(--color-secondary);
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  & svg {
    font-size: var(--font-size-16);

    &.buttonComponent__spinner {
      animation: spinner 3s linear infinite;
      color: var(--color-medium);
    }
  }
  & span {
    font-size: var(--font-size-14);
  }
  &.right {
    flex-direction: row-reverse;
  }
  &.secondary {
    background-color: var(--color-medium);

    &::after {
      background-color: var(--color-dark);
    }
  }
  &.dark.secondary {
    &::after {
      background-color: var(--color-medium-dark);
    }
  }
  &.inactive {
    pointer-events: none;
    background-color: var(--color-light);
    color: rgba(0, 0, 0, 0.2);

    &::after {
      background-color: var(--color-medium);
    }
  }
  &.lightIcon {
    & svg {
      color: var(--color-white);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
