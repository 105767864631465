<template>
  <div class="accessibilityView" :class="theme">
    <h3>Deklaracja dostępności</h3>
    <div v-if="accessibilityLink" class="accessibilityView__content">
      <ExternalLinkButton :href="accessibilityLink" text="Zobacz" icon="eye" />
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import ExternalLinkButton from "@/components/ExternalLinkButton.vue";

export default {
  name: "AccessibilityView",
  components: { ExternalLinkButton },
  computed: {
    theme() {
      return Theme.get();
    },
    accessibilityLink() {
      return this.$store.state.links.accessibility
        ? this.$store.state.links.accessibility
        : null;
    },
  },
};
</script>
