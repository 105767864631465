<template>
  <div class="view" :class="theme">
    <h1 class="view__heading">Kontakt</h1>
    <div class="contact">
      <div
        v-if="contact"
        class="contact__content article"
        :class="theme"
        v-html="contact.content"
      ></div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "ContactView",
  components: {},
  computed: {
    theme() {
      return Theme.get();
    },
    contact() {
      return this.$store.state.contact?.data
        ? this.$store.state.contact.data[0]
        : null;
    },
  },
};
</script>
