<template>
  <div class="modalBackdrop" @click="backdropClose ? close() : null">
    <div class="modalComponent" :class="theme" @click.stop>
      <div class="modalComponent__close" v-if="closeButton" @click.stop="close">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </div>
      <div class="modalComponent__title" v-if="title">{{ title }}</div>
      <div class="modalComponent__text" v-if="text">{{ text }}</div>
      <div class="modalComponent__buttons">
        <ButtonComponent
          v-for="(item, i) in buttons"
          :text="item.text"
          :icon="item.icon"
          :icon-position="item.iconPosition"
          :key="i"
          :secondary="item.color === 'secondary'"
          :inactive="item.inactive"
          @click.stop="$emit(item.action)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "ModalComponent",
  components: {
    ButtonComponent,
  },
  props: {
    title: String,
    text: String,
    buttons: Object,
    backdropClose: Boolean,
    closeButton: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
