<template>
  <div class="informationsView" :class="theme">
    <transition-group name="slideLeft">
      <div
        class="informationsView__list"
        v-if="!$route.params.subsetting"
        key="list"
      >
        <div
          class="informationsView__position"
          @click="$router.push('/settings/informations/info')"
        >
          <div class="settings__text">
            <div class="informationsView__name">Informacje o aplikacji</div>
          </div>
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </div>
        <div
          class="informationsView__position"
          @click="$router.push('/settings/informations/accessibility')"
        >
          <div class="settings__text">
            <div class="informationsView__name">Deklaracja dostępności</div>
          </div>
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </div>
        <div
          class="informationsView__position"
          @click="$router.push('/settings/informations/privacy')"
        >
          <div class="settings__text">
            <div class="informationsView__name">Polityka prywatności</div>
          </div>
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </div>
      </div>
      <InfoView v-if="$route.params.subsetting === 'info'" key="info" />
      <AccessibilityView
        v-if="$route.params.subsetting === 'accessibility'"
        key="accessibility"
      />
      <PrivacyView
        v-if="$route.params.subsetting === 'privacy'"
        key="privacy"
      />
    </transition-group>
  </div>
</template>

<script>
import InfoView from "@/views/Info/view.vue";
import AccessibilityView from "@/views/Accessibility/view.vue";
import PrivacyView from "@/views/Privacy/view.vue";
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "InformationsView",
  components: {
    InfoView,
    AccessibilityView,
    PrivacyView,
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
