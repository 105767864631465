<template>
  <div class="fileComponent" :class="theme" @click="download">
    <div class="fileComponent__content">
      <div class="fileComponent__name">{{ data.name }}</div>
      <div class="fileComponent__details">
        <span class="fileComponent__size">{{ formatSize(data.size) }}</span>
        <span class="fileComponent__date">{{ formatDate(data.stamp) }}</span>
      </div>
    </div>
    <ButtonComponent icon="download" />
  </div>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "FileComponent",
  components: {
    ButtonComponent,
  },
  props: {
    data: Object,
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    formatSize(size) {
      return size / 1024 < 1024
        ? (size / 1024).toFixed(2) + " Kb"
        : (size / 1024 / 1024).toFixed(2) + " Mb";
    },
    download() {
      this.$emit("download", this.data.url, this.data.name);
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
