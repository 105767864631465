<template>
  <button
    class="buttonComponent"
    :class="{
      right: iconPosition === 'right',
      secondary: secondary,
      inactive: inactive || loading,
      dark: theme === 'dark',
      lightIcon: !text,
    }"
    :style="{ width: width + 'px' }"
  >
    <font-awesome-icon v-if="icon && !loading" :icon="icon" />
    <font-awesome-icon
      icon="fa-solid fa-spinner"
      class="buttonComponent__spinner"
      v-if="loading"
    />
    <span v-if="text">{{ loading ? "czekaj" : text }}</span>
  </button>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "ButtonComponent",
  props: [
    "text",
    "icon",
    "iconPosition",
    "secondary",
    "inactive",
    "width",
    "loading",
  ],
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
