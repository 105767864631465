<template>
  <div
    class="popupComponent"
    :class="[color, position, size, theme]"
    :style="{ bottom: bottom }"
  >
    <div class="popupComponent__icon" v-if="position !== 'bottom'">
      <font-awesome-icon :icon="icon" />
    </div>
    <span>{{ text }}</span>
    <div
      class="popupComponent__close"
      v-if="close"
      @click.stop="$emit('update:modelValue', false)"
    >
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import { Capacitor } from "@capacitor/core";

export default {
  name: "PopupComponent",
  props: [
    "position",
    "color",
    "size",
    "close",
    "text",
    "modelValue",
    "displayTime",
  ],
  mounted() {
    setTimeout(() => {
      this.$emit("update:modelValue", false);
    }, this.displayTime * 1000);
  },
  computed: {
    icon() {
      if (this.color === "success") return "fa-solid fa-check";
      if (this.color === "warning") return "fa-solid fa-triangle-exclamation";
      if (this.color === "error") return "fa-solid fa-ban";
      return "";
    },
    bottom() {
      if (Capacitor.getPlatform() === "ios" && this.position === "bottom")
        return "74px";
      if (this.position === "bottom") return "64px";
      return "unset";
    },
    theme() {
      return Theme.get();
    },
  },
};
</script>
