<template>
  <div class="fractionsLegend" :class="theme">
    <h3>Legenda:</h3>
    <div class="fractionsLegend__fractions">
      <div
        class="fractionsLegend__fraction"
        v-for="fraction in fractions()"
        :key="fraction.id"
      >
        <div :style="{ 'background-color': fraction.color }"></div>
        <span>{{ fraction.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "FileComponent",
  methods: {
    fractions() {
      return this.$store.state.fractions.data;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
