<template>
  <div class="modalBackdrop" @click="backdropClose ? $emit('close') : null">
    <div class="notificationModal" :class="theme" @click.stop>
      <div
        class="notificationModal__close"
        v-if="closeButton"
        @click.stop="$emit('close')"
      >
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </div>
      <div
        class="notificationModal__title"
        v-text="!notification ? 'Nowe powiadomienie' : 'Edycja powiadomienia'"
      ></div>
      <div class="notificationModal__text">
        Wybierz termin i godzinę aby ustawić powiadomienie dla wybranego rejonu.
      </div>
      <SelectList
        label="Termin powiadomień"
        :expandable="true"
        :items="getSettings().notificationTerms"
        v-model="term"
      />
      <SelectList
        label="Godzina powiadomień"
        :expandable="true"
        :items="hours()"
        v-model="hour"
      />
      <div class="notificationModal__buttons">
        <ButtonComponent
          text="Anuluj"
          :secondary="true"
          v-if="getUserSettings().favoriteDistrict"
          @click="$emit('close')"
        />
        <ButtonComponent
          :text="!notification ? 'Utwórz powiadomienie' : 'Zapisz zmiany'"
          icon="save"
          icon-position="left"
          @click="save"
          :loading="saving"
        />
      </div>
      <transition name="fade">
        <div
          class="notificationModal__notifications"
          v-if="getActiveNotifications().length && list"
        >
          <h3>Ustawione powiadomienia:</h3>
          <div class="notificationModal__notificationsList">
            <transition-group name="fade">
              <NotificationComponent
                v-for="item in getActiveNotifications()"
                :notification="item"
                :key="item.id"
              />
            </transition-group>
          </div>
        </div>
      </transition>
    </div>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="3"
        size="large"
        position="top"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import SelectList from "@/components/SelectList/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import NotificationComponent from "@/components/Notification/component.vue";
import Theme from "@/assets/js/Theme.js";
import Notifications from "@/assets/js/Notifications.js";

export default {
  name: "ModalComponent",
  data() {
    return {
      popupData: {
        display: false,
        color: "",
        text: "",
      },
      term:
        this.notification && this.notification.term !== false
          ? this.notification.term
          : "not set",
      hour:
        this.notification && this.notification.hour !== false
          ? this.notification.hour
          : "not set",
      saving: false,
    };
  },
  components: {
    ButtonComponent,
    SelectList,
    PopupComponent,
    NotificationComponent,
  },
  props: ["backdropClose", "closeButton", "district", "notification", "list"],
  methods: {
    hours() {
      const hours = [{ name: "wybierz z listy", value: false }];
      for (let i = 1; i < 24; i++) {
        const hour = i < 10 ? "0" + i : i;
        hours.push({ name: hour + ":00", value: i });
      }
      return hours;
    },
    async save() {
      if (!this.saving) {
        if (
            this.term === "not set" ||
            this.hour === "not set" ||
            this.term === false ||
            this.hour === false
        ) {
          this.showPopup(
              "warning",
              "Musisz wybrać termin oraz godzinę dla powiadomienia!"
          );
          return;
        }
        if (this.getNotification()) {
          this.showPopup(
              "warning",
              "Istnieje już takie powiadomienie dla tego rejonu!"
          );
          return;
        }
        this.saving = true;
        if (!this.notification) {
          const saved = await this.setNotification();

          switch (saved) {
            case "success":
              this.$emit("saved");
              this.$emit("close");
              break;
            case "noPerms":
              this.$store.commit("modals/OPEN_PERMISSION_MODAL");
              break;
            case "noSelect":
              this.showPopup(
                  "warning",
                  "Musisz wybrać termin oraz godzinę dla powiadomienia!"
              );
              break;
            case "noReload":
              this.showPopup("error", "Wystąpił błąd zapisu powiadomień!");
              break;
            default:
              this.showPopup(
                  "error",
                  "Wystąpił nieznany błąd! Spróbuj ponownie."
              );
          }

          this.saving = false;
        } else {
          if (await this.editNotification(this.notification.id)) {
            this.$emit("saved");
            this.$emit("close");
            this.saving = false;
          }
        }
      }
    },
    async setNotification() {
      return await Notifications.setWastePushNotification(
        this.district,
        this.term,
        this.hour
      );
    },
    editNotification(id) {
      return Notifications.edit(id, this.district, this.term, this.hour);
    },
    getNotification() {
      return Notifications.get(this.district, this.term, this.hour);
    },
    getActiveNotifications() {
      return Notifications.getActiveNotifications(this.district);
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
    getSettings() {
      return this.$store.state.settings;
    },
    getUserSettings() {
      return this.$store.state.userSettings;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
