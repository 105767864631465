<template>
  <div class="checkboxComponent" :class="theme">
    <div
      class="checkboxComponent__field"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <font-awesome-icon icon="fa-solid fa-check" v-if="modelValue" />
    </div>
    <div
      class="checkboxComponent__label"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "CheckboxComponent",
  props: ["modelValue", "label"],
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
