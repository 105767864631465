<template>
  <div class="view" :class="theme" id="files">
    <h1 v-if="$route.params.category" class="view__heading">
      {{ category ? category.name : "Dokumenty" }}
    </h1>
    <RefreshComponent :top="refreshPosition" :loading="loading" />
    <transition name="fadeBlur">
      <div class="view__loader" v-if="loading">
        <LoaderComponent :display="loading" />
      </div>
    </transition>
    <transition-group name="fadeBlur">
      <div
        class="documents"
        v-if="!$route.params.category && !loading"
        key="categories"
      >
        <FileCategory
          v-for="item in categories"
          :name="item.name"
          :category="item.id"
          :key="item.id"
        />
        <InlineNotification
          icon="fa-regular fa-folder-open"
          text="Nie ma jeszcze żadnych kategorii dokumentów."
          v-if="!categories.length && !loading"
          action="files"
          @reload="refreshData()"
        />
      </div>

      <div class="documents" v-else-if="!loading" key="category">
        <FileComponent
          v-for="item in category.files"
          :data="item"
          :key="item.id"
          @download="downloadAction"
        />
        <InlineNotification
          icon="fa-solid fa-file-circle-xmark"
          text="W tej kategorii nie ma jeszcze żadnych plików."
          v-if="!category.files?.length && !loading"
          action="files"
          @reload="refreshData()"
        />
      </div>
    </transition-group>

    <transition name="fadeBlurScale">
      <ModalComponent
        v-if="modal"
        @close="modalClose"
        @download="downloadFile"
        title="Czy na pewno chcesz pobrać dokument?"
        :backdrop-close="true"
        :close-button="true"
        :buttons="modalButtons"
      />
    </transition>
    <transition name="slideLong">
      <PopupComponent
        v-if="downloadSuccess"
        color="success"
        size="large"
        text="Dokument zapisano pomyślnie!"
        v-model="downloadSuccess"
        :displayTime="3"
        position="top"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import FileComponent from "@/components/File/component.vue";
import FileCategory from "@/components/FileCategory/component.vue";
import ModalComponent from "@/components/Modal/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import InlineNotification from "@/components/InlineNotification/component.vue";
import RefreshComponent from "@/components/Refresh/component.vue";
import LoaderComponent from "@/components/Loader/component.vue";
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data.js";

export default {
  name: "DocumentsView",
  components: {
    FileComponent,
    FileCategory,
    ModalComponent,
    PopupComponent,
    InlineNotification,
    RefreshComponent,
    LoaderComponent,
  },
  data() {
    return {
      modal: false,
      fileURL: null,
      fileName: null,
      downloadSuccess: false,
      modalButtons: [
        {
          text: "Anuluj",
          color: "secondary",
          action: "close",
        },
        {
          text: "Pobierz",
          action: "download",
        },
      ],
      view: null,
      refreshPosition: -100,
      touchStart: false,
      loading: false,
    };
  },
  methods: {
    async refreshData() {
      this.loading = true;
      await Data.load("files");
      this.loading = false;
      this.refreshPosition = 0;
    },
    downloadAction(fileURL, fileName) {
      this.modal = true;
      this.fileURL = fileURL;
      this.fileName = fileName;
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.fileURL;
      link.download = this.fileName;
      link.target = "_blank";
      link.click();

      this.modal = false;
      this.downloadSuccess = true;
    },
    modalClose() {
      this.modal = false;
      this.fileURL = null;
      this.fileName = null;
    },
  },
  beforeMount() {
    this.refreshData();
  },
  mounted() {
    this.view = document.querySelector("#files");
    this.view.addEventListener(
      "touchstart",
      (e) => {
        const touch = e.targetTouches[0];
        this.touchStart = touch.screenY;
      },
      false
    );
    this.view.addEventListener(
      "touchmove",
      (e) => {
        if (this.touchStart && this.view.scrollTop <= 0) {
          const touch = e.targetTouches[0];
          this.refreshPosition =
            touch.screenY - this.touchStart < 110
              ? touch.screenY - this.touchStart
              : 110;
        }
      },
      false
    );
    this.view.addEventListener(
      "touchend",
      () => {
        if (this.refreshPosition >= 110) {
          this.refreshData();
        } else {
          this.refreshPosition = 0;
        }
        this.touchStart = false;
      },
      false
    );
  },
  computed: {
    theme() {
      return Theme.get();
    },
    categories() {
      return this.$store.state.files;
    },
    category() {
      return this.$store.state.files.find(
        (item) => item.id == this.$route.params.category
      );
    },
  },
  watch: {
    $route(to, from) {
      this.refreshData();
    },
  },
};
</script>
