<template>
  <div
    class="wastePointView article"
    :class="theme"
    v-html="getArticle()"
  ></div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "WastePointView",
  methods: {
    getArticle() {
      return this.$store.state.point.data[0].content.replace(
        /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/g,
        " "
      );
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
