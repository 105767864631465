import store from "@/store/index.js";

class DB {
  static async get(endpoint) {
    try {
      const data = await fetch(store.state.settings.appAPI + endpoint, {
        method: "GET",
        headers: {
          "X-Site-Id": store.state.settings.appAPISiteHeader,
        },
      });
      return data.json();
    } catch (error) {
      return { error: error };
    }
  }

  static async post(endpoint, body) {
    try {
      const data = await fetch(store.state.settings.appAPI + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Site-Id": store.state.settings.appAPISiteHeader,
        },
        body: JSON.stringify(body),
      });
      return data.json();
    } catch (error) {
      return { error: error };
    }
  }

  static async patch(endpoint, body) {
    try {
      const data = await fetch(store.state.settings.appAPI + endpoint, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-Site-Id": store.state.settings.appAPISiteHeader,
        },
        body: JSON.stringify(body),
      });
      return data.json();
    } catch (error) {
      return { error: error };
    }
  }

  static async put(endpoint, body) {
    try {
      const data = await fetch(store.state.settings.appAPI + endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Site-Id": store.state.settings.appAPISiteHeader,
        },
        body: JSON.stringify(body),
      });
      return data.json();
    } catch (error) {
      return { error: error };
    }
  }

  static async delete(endpoint) {
    try {
      await fetch(store.state.settings.appAPI + endpoint, {
        method: "DELETE",
        headers: {
          "X-Site-Id": store.state.settings.appAPISiteHeader,
        },
      });
      return true;
    } catch (error) {
      return { error: error };
    }
  }
}

export default DB;
