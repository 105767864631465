export default {
  namespaced: true,
  state: {
    permissionsModalOpened: false,
    deletedNotificationsModalOpened: false,
  },
  getters: {
    isPermissionsModalOpened: (state) => state.permissionsModalOpened,
    isDeletedNotificationsModalOpened: (state) => state.deletedNotificationsModalOpened,
  },
  mutations: {
    CLOSE_PERMISSION_MODAL(state) {
      state.permissionsModalOpened = false;
    },
    OPEN_PERMISSION_MODAL(state) {
      state.permissionsModalOpened = true;
    },
    CLOSE_DELETED_NOTIFICATIONS_MODAL(state) {
      state.deletedNotificationsModalOpened = false;
    },
    OPEN_DELETED_NOTIFICATIONS_MODAL(state) {
      state.deletedNotificationsModalOpened = true;
    },
  },
  actions: {},
};
