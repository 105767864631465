import DB from "@/assets/js/DB.js";
import store from "@/store/index.js";

class Data {
  static async getFiles() {
    const fileCategories = await DB.get("/folders");
    const allFiles = [];
    if (fileCategories.data)
      for (const article of fileCategories.data) {
        const files = await DB.get("/documents/" + article.id);
        allFiles.push({
          id: article.id,
          name: article.name,
          files: files.data,
        });
      }
    return allFiles;
  }

  static sortArticles(articles) {
    articles?.data?.sort((a, b) => {
      return new Date(b.start_stamp) - new Date(a.start_stamp);
    });
    return articles;
  }

  static async getAttachments(article) {
    const files = await DB.get("/articles/" + article + "/files");
    if (files.data) return files.data;
    return false;
  }

  static async load(data = "all") {
    if (data === "all" || data === "news")
      store.state.articles = this.sortArticles(await DB.get("/articles"));
    if (data === "all" || data === "files")
      store.state.files = await this.getFiles();
    if (data === "all" || data === "waste")
      store.state.waste = await DB.get("/waste");
    if (data === "all" || data === "districts") {
      const allDistrictsBefore = store.state.districts.data;
      store.state.districts = await DB.get("/districts");
      const allDistrictsAfter = store.state.districts.data;
      this.removeNotificationForRemovedDistricts(
          allDistrictsBefore,
          allDistrictsAfter
      );
    }
    if (data === "all" || data === "fractions")
      store.state.fractions = await DB.get("/wastefractions");
    if (data === "all" || data === "contact")
      store.state.contact = await DB.get("/static-sites/2");
    if (data === "all" || data === "payment")
      store.state.payment = await DB.get("/static-sites/6");
    if (data === "all" || data === "wastePoint")
      store.state.point = await DB.get("/static-sites/1");
    if (data === "all" || data === "links")
      store.state.links = await DB.get("/webspaces/policy-links");
    store.dispatch("saveState");
  }

  static getAllDistrictIds(districts) {
    if (districts) {
      const allDistrictsIds = [];
      districts.forEach((district) => {
        allDistrictsIds.push(district.id);
      });
      return allDistrictsIds;
    }
  }
  static removeNotificationForRemovedDistricts(
      allDistrictsBefore,
      allDistrictsAfter
  ) {
    const allDistrictsIdsBefore = this.getAllDistrictIds(allDistrictsBefore);
    const allDistrictsIdsAfter = this.getAllDistrictIds(allDistrictsAfter);
    const set1 = new Set(allDistrictsIdsBefore);
    const set2 = new Set(allDistrictsIdsAfter);
    if (allDistrictsIdsBefore) {
      const deletedDistricts = allDistrictsIdsBefore.filter(
          (element) => !set2.has(element)
      );
      if (deletedDistricts?.length) {
        this.clearNotificationsIfDistrictDeleted(deletedDistricts);
        store.dispatch("saveState");
        store.commit("modals/OPEN_DELETED_NOTIFICATIONS_MODAL");
      }
    }
  }
  static clearNotificationsIfDistrictDeleted(deletedDistricts) {
    store.state.userSettings.notifications.waste =
        store.state.userSettings.notifications.waste.filter(
            (item) => !deletedDistricts.includes(item.district)
        );
  }
  static async getArticleGallery(articleId) {
    const images = await DB.get("/articles/" + articleId + "/images");
    if (images.data) return images.data;
    return false;
  }

  static async getNotifications() {
    const notifications = await DB.get("/notifications");
    if (notifications.data) return notifications.data;
    return false;
  }

  static async getNotification(id) {
    const notifications = await DB.get(`/notifications/${id}`);
    if (notifications.data) return notifications.data;
    return false;
  }

  static async setNotificationPreferences() {
    const FCMToken = store.state.settings.FCMToken;
    const preferences = store.state.userSettings.notifications;

    if (!FCMToken) {
      return false;
    }

    const notificationsPreferences = await DB.patch(
      `/notification-preferences`,
      {
        FCMToken: FCMToken,
        announcementNotification: preferences.announcementNotification,
        paymentNotification: preferences.paymentNotification,
      }
    );

    if (notificationsPreferences.data) return notificationsPreferences.data;
    return false;
  }

  static async addAlertSubscriptions(districtId, term, hour) {
    let FCMToken = store.state.settings.FCMToken;

    const sleep = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

    if (!FCMToken) {
      await sleep(1000);
      FCMToken = store.state.settings.FCMToken;
    }

    if (!FCMToken) {
      return false;
    }

    const alert = await DB.post(`/alert-subscriptions`, {
      fcm_token: FCMToken,
      days_prior: Math.abs(term),
      hour: `${hour}:00`,
      district_id: districtId,
    });

    if (alert) return alert;
    return false;
  }

  static async updateAlertSubscriptions(alertId, districtId, term, hour) {
    const FCMToken = store.state.settings.FCMToken;

    if (!FCMToken) {
      return false;
    }

    const alert = await DB.put(`/alert-subscriptions/${alertId}`, {
      fcm_token: FCMToken,
      days_prior: Math.abs(term),
      hour: `${hour}:00`,
      district_id: districtId,
    });

    if (alert) return alert;
    return false;
  }

  static async deleteAlertSubscriptions(alertId) {
    const FCMToken = store.state.settings.FCMToken;

    if (!FCMToken) {
      return false;
    }

    const alert = await DB.delete(`/alert-subscriptions/${alertId}`);

    if (alert) return alert;

    return false;
  }
}

export default Data;
