<template>
  <router-link
    :to="config.route"
    class="mainMenuIcon"
    :class="theme + ' ' + active"
  >
    <div
      class="mainMenuIcon__icon"
      :style="[
        config.color === 'default' ? '' : { 'border-color': config.color },
      ]"
    >
      <font-awesome-icon :icon="config.icon" />
    </div>
    <div class="mainMenuIcon__name">{{ config.name }}</div>
  </router-link>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "MainMenuIcon",
  props: {
    config: Object,
  },
  computed: {
    theme() {
      return Theme.get();
    },
    active() {
      return this.$route.path === this.config.route ||
        (this.config.route !== "/" &&
          this.$route.path.includes(this.config.route))
        ? "active"
        : "";
    },
  },
};
</script>
