import store from "@/store/index.js";
import router from "@/router/index.js";
import DB from "@/assets/js/DB.js";
import Data from "@/assets/js/Data";
import { v4 as uuid } from "uuid";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import DateExtensions from "@/assets/js/Date.js";

class Notifications {
  static async setWastePushNotification(district, term, hour) {
    if (district && term !== "not set" && hour !== "not set") {
      const perms = await this.registerPush(true);
      if (!perms) return "noPerms";

      const alert = await Data.addAlertSubscriptions(district, term, hour);

      if (!alert) {
        return "error";
      }

      store.state.userSettings.notifications.waste.push({
        id: alert.id,
        district: alert.district.id,
        term: term,
        hour: hour,
      });

      store.dispatch("saveState");

      return "success";
    }

    return "noSelect";
  }

  static async delete(id) {
    const isSuccess = await Data.deleteAlertSubscriptions(id);

    if (!isSuccess) {
      return false;
    }

    const indexToDelete =
      store.state.userSettings.notifications.waste.findIndex(
        (item) => item.id === id
      );

    if (indexToDelete > -1) {
      store.state.userSettings.notifications.waste.splice(indexToDelete, 1);
    }

    store.dispatch("saveState");
    return true;
  }

  static async deleteAll() {
    const pendingNotifications = await LocalNotifications.getPending();
    if (
      pendingNotifications &&
      pendingNotifications.notifications &&
      pendingNotifications.notifications.length > 0
    ) {
      await this.removeLocalListeners();
      await LocalNotifications.cancel({
        notifications: pendingNotifications.notifications,
      });
    }
  }

  static async edit(id, district, term, hour) {
    if (this.get(district, term, hour)) return false;

    const alert = await Data.updateAlertSubscriptions(id, district, term, hour);

    if (!alert) {
      return false;
    }

    const temp = this.getById(id);
    this.getById(id).term = term;
    this.getById(id).hour = hour;

    return true;
  }

  static async reload() {
    await this.deleteAll();
    const allNotifications = this.generate();
    await this.registerPush();

    // if (allNotifications.length) {
    //   if (await this.checkPerms()) {
    //     await LocalNotifications.schedule({
    //       notifications: allNotifications,
    //     });
    //     await this.addLocalListeners();
    //   }
    // }
    return true;
  }

  static sortByDate(array) {
    return array.sort((a, b) => {
      if (a.schedule.at < b.schedule.at) return -1;
      if (a.schedule.at > b.schedule.at) return 1;
      return 0;
    });
  }

  static generate(limit = 128) {
    let notifications = [];
    store.state.userSettings.notifications.waste.forEach((notification) => {
      const data = store.state.waste.data.filter(
        (item) => item.id_district === notification.district
      );
      data.forEach((term) => {
        const notificationDate = DateExtensions.setHour(
          DateExtensions.addDays(new Date(term.date), notification.term),
          notification.hour
        );
        let body = `${
          notification.term === 0
            ? "Dziś"
            : notification.term === -1
            ? "Jutro"
            : notification.term === -2
            ? "Pojutrze"
            : "Za dwa dni"
        } (${term.date.split("T")[0]}) zostaną odebrane odpady frakcji ${
          term.fraction
        } z rejonu ${
          store.state.districts.data.find(
            (item) => item.id === notification.district
          ).name
        }: ${
          store.state.districts.data.find(
            (item) => item.id === notification.district
          ).description
        }`;
        if (notificationDate >= new Date()) {
          notifications.push({
            title: `${
              notification.term === 0
                ? "Dziś"
                : notification.term === -1
                ? "Jutro"
                : notification.term === -2
                ? "Pojutrze"
                : "Za dwa dni"
            } zaplanowany odbiór odpadów`,
            body: body,
            largeBody: body,
            id: Math.floor((Date.now() / 100000) * Math.random()),
            schedule: { at: notificationDate, allowWhileIdle: true },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: { district: notification.district },
          });
        }
      });
    });
    // OGRANICZENIE ILOŚCI POWIADOMIEŃ DO 64 dla iOS i do 128 dla Android
    if (limit) {
      if (Capacitor.getPlatform() === "ios") limit = 64;
      notifications = this.sortByDate(notifications);
      if (notifications.length > limit) {
        notifications = notifications.slice(0, limit - 1);
        notifications.push({
          title: "Ustaw nowe powiadomienia",
          body: "Jeżeli nadal chcesz otrzymywać powiadomienia o wywozie odpadów otwórz aplikację i zezwól na ustawienie nowych powiadomień.",
          id: Math.floor((Date.now() / 100000) * Math.random()),
          schedule: {
            at: notifications[limit - 2].schedule.at,
            allowWhileIdle: true,
          },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null,
        });
      }
    }
    return notifications;
  }

  static async checkNew() {
    const pendingNotifications = await LocalNotifications.getPending();
    const allNotifications = this.generate();
    if (
      allNotifications.length &&
      pendingNotifications &&
      pendingNotifications.notifications &&
      allNotifications.length > pendingNotifications.notifications.length
    )
      return true;
    return false;
  }

  static get(district, term, hour) {
    const notification = store.state.userSettings.notifications.waste.filter(
      (item) =>
        item.district === district && item.term === term && item.hour === hour
    );
    if (notification.length) return notification[0];
    return false;
  }

  static getById(id) {
    const notification = store.state.userSettings.notifications.waste.filter(
      (item) => item.id === id
    );
    if (notification.length) return notification[0];
    return false;
  }

  static getActiveNotifications(district) {
    return store.state.userSettings.notifications.waste.filter(
      (item) => item.district === district
    );
  }

  static getAllNotifications() {
    return store.state.userSettings.notifications;
  }

  static async getPendingNotifications() {
    const pending = await LocalNotifications.getPending();
    return pending;
  }

  static async setState(prop, value) {
    const perms = await this.checkPerms();
    if (!perms) return false;
    store.commit("changeNotificationsSettings", { prop: prop, val: value });
    store.dispatch("saveState");
    return true;
  }

  static async checkPerms() {
    const perms = await LocalNotifications.checkPermissions();
    if (perms.display === "granted") return true;
    const request = await LocalNotifications.requestPermissions();
    if (request.display === "granted") return true;
    return false;
  }

  static async addLocalListeners() {
    await LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (notification) => {
        router.push(
          "/waste/schedule/" + notification.notification.extra.district
        );
      }
    );
  }

  static async removeLocalListeners() {
    await LocalNotifications.removeAllListeners();
  }

  // PUSH NOTIFICATIONS

  static async setPushState(prop, value) {
    if (
      Capacitor.getPlatform() !== "ios" &&
      Capacitor.getPlatform() !== "android"
    )
      return false;
    const perms = await this.registerPush();
    if (!perms) return false;
    store.commit("changeNotificationsSettings", { prop: prop, val: value });
    store.dispatch("saveState");
    if (prop === "news" && value === false) await this.unregisterPush();
    return true;
  }

  static async enableAllNotifications() {
    if (
      Capacitor.getPlatform() !== "ios" &&
      Capacitor.getPlatform() !== "android"
    )
      return false;

    store.commit("changeNotificationsSettings", {
      prop: "newsNotification",
      val: true,
    });
    store.commit("changeNotificationsSettings", {
      prop: "announcementNotification",
      val: true,
    });
    store.commit("changeNotificationsSettings", {
      prop: "paymentNotification",
      val: true,
    });
    await store.dispatch("saveState");

    return true;
  }

  static async addListeners() {
    await PushNotifications.addListener("registration", (token) => {
      store.commit("setFCMToken", { token: token.value });

      DB.post("/notifications/register-token", { token: token.value }).then(
        async (res) => {
          if (res.status === "success") {
            await Notifications.enableAllNotifications();
            await Data.setNotificationPreferences();
          }
        }
      );
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.log(err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log(notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        this.handleActionNotification(action.notification.data);
      }
    );
  }

  static async removeListeners() {
    await PushNotifications.removeAllListeners();
  }

  static handleActionNotification(notificationData) {
    if (!notificationData.action) {
      return;
    }

    switch (notificationData.action) {
      case "open_notification":
        router.push({
          name: "announcement",
          params: { id: `${notificationData.id}` },
        });

        break;
      case "open_schedule":
        router.push(`/waste/myschedule`);

        break;
      case "open_payment_info":
        router.push(`/payment`);

        break;
    }
  }

  static async registerPush(forceRequestPermissions = false) {
    if (Capacitor.getPlatform() === "web") {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    let listenersAdded = false;

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
      await this.addListeners();
      listenersAdded = true;
    } else if (
      permStatus.receive === "prompt-with-rationale" &&
      forceRequestPermissions
    ) {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") return false;

    if (!listenersAdded) {
      await this.addListeners();
    }

    await PushNotifications.register();
    return true;
  }

  static async unregisterPush() {
    await PushNotifications.unregister();
    this.removeListeners();
  }
}

export default Notifications;
