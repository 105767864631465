import { createRouter, createWebHistory } from "vue-router";
import WasteView from "@/views/Waste/view.vue";
import DocumentsView from "@/views/Documents/view.vue";
import NewsView from "@/views/News/view.vue";
import OneNewsView from "@/views/OneNews/view.vue";
import ContactView from "@/views/Contact/view.vue";
import SettingsView from "@/views/Settings/view.vue";
import EUView from "@/views/EU/view.vue";
import AnnouncementView from "@/views/AnnouncementView.vue";
import AnnouncementsListView from "@/views/AnnouncementsListView.vue";
import PaymentView from "@/views/PaymentView.vue";

const routes = [
  {
    path: "/",
    redirect: "/waste",
  },
  {
    path: "/waste/eu",
    name: "eu",
    component: EUView,
  },
  {
    path: "/waste",
    name: "waste",
    component: WasteView,
  },
  {
    path: "/waste/:section",
    name: "wastesection",
    component: WasteView,
  },
  {
    path: "/waste/:section/:district",
    name: "wastesectiondistrict",
    component: WasteView,
  },
  {
    path: "/waste/documents",
    name: "documents",
    component: DocumentsView,
  },
  {
    path: "/waste/documents/:category",
    name: "documents",
    component: DocumentsView,
  },
  {
    path: "/news",
    name: "allnews",
    component: NewsView,
  },
  {
    path: "/news/:id",
    name: "news",
    component: OneNewsView,
  },
  {
    path: "/waste/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentView,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
  {
    path: "/settings/:setting",
    name: "setting",
    component: SettingsView,
  },
  {
    path: "/settings/:setting/:subsetting",
    name: "subsetting",
    component: SettingsView,
  },
  {
    path: "/announcements",
    name: "announcements",
    component: AnnouncementsListView,
  },
  {
    path: "/announcements/:id",
    name: "announcement",
    component: AnnouncementView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
