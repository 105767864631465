<template>
  <div
    class="fileCategory"
    :class="theme"
    @click="$router.push('/waste/documents/' + category)"
  >
    <div class="fileCategory__content">
      <div class="fileCategory__name">{{ name }}</div>
    </div>
    <ButtonComponent icon="arrow-right" />
  </div>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "FileComponent",
  components: {
    ButtonComponent,
  },
  props: ["category", "name"],
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
