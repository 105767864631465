<template>
  <label class="switch">
    <input type="checkbox" :checked="modelValue" @click="toggleCheckbox" />
    <div class="switch__slider round"></div>
  </label>
</template>

<script>
import "./style.scss";

export default {
  name: "ToggleSwitch",
  props: ["modelValue", "label"],
  data() {
    return {
      checked: this.modelValue,
    };
  },
  methods: {
    toggleCheckbox() {
      this.checked = !this.checked;
      this.$emit("update:modelValue", this.checked);
      this.$emit("setCheckboxVal", this.checked);
    },
  },
};
</script>
