<template>
  <div class="view" id="news" :class="theme">
    <h1 class="view__heading">Aktualności</h1>
    <div class="oneNews" :class="theme">
      <div class="oneNews__image">
        <img
          :src="
            article.photo_path
              ? article.photo_path_full
              : '/img/default/' + getSettings().appDefaultNewsImage
          "
        />
        <div class="oneNews__date">{{ formatDate(article.start_stamp) }}</div>
      </div>
      <div class="oneNews__text">
        <div class="oneNews__title">{{ article.title }}</div>
        <div class="oneNews__content article" v-html="article.content"></div>
      </div>
      <div class="oneNews__gallery">
        <transition name="fadeBlur">
          <div class="view__loader" v-if="loadingImages">
            <LoaderComponent :display="loadingImages" />
          </div>
        </transition>
        <img
          v-for="(image, key) in galleryImages"
          :src="image.url"
          :alt="image.name"
          :key="key"
        />
      </div>
      <div class="oneNews__attachments" v-if="attachments">
        <transition name="fadeBlur">
          <div class="view__loader" v-if="loading">
            <LoaderComponent :display="loading" />
          </div>
        </transition>
        <FileComponent
          v-for="item in attachments"
          :data="item"
          :key="item.id"
          @download="downloadAction"
        />
      </div>
      <ButtonComponent
        text="Powrót"
        icon="chevron-left"
        icon-position="left"
        @click="this.$router.go(-1)"
      />
    </div>
    <transition name="fadeBlurScale">
      <ModalComponent
        v-if="modal"
        @close="modalClose"
        @download="downloadFile"
        title="Czy na pewno chcesz pobrać dokument?"
        :backdrop-close="true"
        :close-button="true"
        :buttons="modalButtons"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import LoaderComponent from "@/components/Loader/component.vue";
import FileComponent from "@/components/File/component.vue";
import ModalComponent from "@/components/Modal/component.vue";
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data.js";

export default {
  name: "OneNewsView",
  components: {
    ButtonComponent,
    LoaderComponent,
    FileComponent,
    ModalComponent,
  },
  data() {
    return {
      modal: false,
      fileURL: null,
      fileName: null,
      downloadSuccess: false,
      modalButtons: [
        {
          text: "Anuluj",
          color: "secondary",
          action: "close",
        },
        {
          text: "Pobierz",
          action: "download",
        },
      ],
      galleryImages: [],
      attachments: null,
      view: null,
      loading: false,
      loadingImages: false,
    };
  },
  methods: {
    async loadGallery() {
      if (this.article && this.$route.params.id) {
        this.loadingImages = true;
        this.galleryImages = await Data.getArticleGallery(
          this.$route.params.id
        );
        this.loadingImages = false;
      }
    },
    async loadAttachments() {
      if (this.article && this.$route.params.id) {
        this.loading = true;
        this.attachments = await Data.getAttachments(this.$route.params.id);
        this.loading = false;
      }
    },
    downloadAction(fileURL, fileName) {
      this.modal = true;
      this.fileURL = fileURL;
      this.fileName = fileName;
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.fileURL;
      link.download = this.fileName;
      link.target = "_blank";
      link.click();

      this.modal = false;
      this.downloadSuccess = true;
    },
    modalClose() {
      this.modal = false;
      this.fileURL = null;
      this.fileName = null;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    getSettings() {
      return this.$store.state.settings;
    },
  },
  mounted() {
    this.loadGallery();
    this.loadAttachments();
  },
  computed: {
    theme() {
      return Theme.get();
    },
    article() {
      return this.$store.state.articles.data.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );
    },
  },
};
</script>
