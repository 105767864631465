<template>
  <div class="wcagMenu" :class="theme">
    <section class="wcagMenu__fonts">
      <div
        class="wcagMenu__button"
        :class="{ active: font === 'normal' }"
        @click="fontSize('normal')"
      >
        <font-awesome-icon icon="fa-solid fa-a" />
      </div>
      <div
        class="wcagMenu__button"
        :class="{ active: font === 'medium' }"
        @click="fontSize('medium')"
      >
        <font-awesome-icon icon="fa-solid fa-a" />
      </div>
      <div
        class="wcagMenu__button"
        :class="{ active: font === 'large' }"
        @click="fontSize('large')"
      >
        <font-awesome-icon icon="fa-solid fa-a" />
      </div>
    </section>
    <section class="wcagMenu__contrast">
      <div
        class="wcagMenu__button"
        :class="{ active: contrast }"
        @click="toggleContrast()"
      >
        <font-awesome-icon icon="fa-solid fa-circle-half-stroke" />
      </div>
    </section>
    <section class="wcagMenu__gaps">
      <div
        class="wcagMenu__button"
        :class="{ active: letter }"
        @click="toggleLetterSpacing()"
      >
        <font-awesome-icon icon="fa-solid fa-text-width" />
      </div>
      <div
        class="wcagMenu__button"
        :class="{ active: line }"
        @click="toggleLineHeight()"
      >
        <font-awesome-icon icon="fa-solid fa-text-height" />
      </div>
    </section>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "WcagMenu",
  data() {
    return {
      font: "normal",
      contrast: false,
      letter: false,
      line: false,
      tmp: "",
    };
  },
  methods: {
    fontSize(size) {
      this.font = size;
      document.documentElement.classList.remove("normal", "medium", "large");
      document.documentElement.classList.add(size);
    },
    toggleContrast() {
      this.contrast = !this.contrast;
      document.documentElement.classList.toggle("contrast");

      if (this.$store.state.userSettings.appTheme !== "light") {
        this.tmp = this.$store.state.userSettings.appTheme;
        this.$store.commit("changeUserSettings", {
          prop: "appTheme",
          val: "light",
        });
        document.documentElement.classList.remove("dark");
      } else if (this.tmp) {
        this.$store.commit("changeUserSettings", {
          prop: "appTheme",
          val: this.tmp,
        });
        document.documentElement.classList.add(this.theme);
        this.tmp = "";
      }
    },
    toggleLineHeight() {
      this.line = !this.line;
      document.documentElement.classList.toggle("line");
    },
    toggleLetterSpacing() {
      this.letter = !this.letter;
      document.documentElement.classList.toggle("letter");
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
