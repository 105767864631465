<template>
  <div class="settings-position" :class="theme">
    <div class="settings-position__title">
      <span>{{ label }}</span>
    </div>
    <ToggleSwitch
      v-if="checkbox"
      v-model="checked"
      @setCheckboxVal="settingChange"
    />
    <font-awesome-icon v-else icon="fa-solid fa-chevron-right" />
  </div>
</template>

<script>
import "./style.scss";
import ToggleSwitch from "@/components/ToggleSwitch/component.vue";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "SettingsPosition",
  components: {
    ToggleSwitch,
  },
  props: ["modelValue", "label", "icon", "iconStyle", "checkbox"],
  data() {
    return {
      checked: this.modelValue,
    };
  },
  methods: {
    settingChange(value) {
      this.$emit("update:modelValue", value);
      this.$emit("setCheckboxVal", value);
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
