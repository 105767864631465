import store from "@/store/index.js";

class Theme {
  static get() {
    let theme = store.state.userSettings.appTheme;
    if (theme === "default") {
      theme =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light";
    }
    store.commit("changeSettings", { prop: "tmpTheme", val: theme });
    return theme;
  }

  static set(theme) {
    store.commit("changeUserSettings", { prop: "appTheme", val: theme });
    document.documentElement.classList.remove("dark", "light", "default");
    document.documentElement.classList.add(this.get(store));
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", this.get(store) === "dark" ? "#222" : "#689F38");
    store.dispatch("saveState");
  }
}
export default Theme;
