<template>
  <div class="themeView" :class="theme">
    <h3>Motyw aplikacji</h3>
    <SelectList
      :expandable="false"
      :items="getSettings().themes"
      :selected="themeName"
      @select="changeTheme"
    />
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import SelectList from "@/components/SelectList/component.vue";

export default {
  name: "ThemeView",
  components: {
    SelectList,
  },
  methods: {
    getThemeName(theme) {
      return this.getSettings().themes.find((item) => item.value === theme)
        .name;
    },
    getSettings() {
      return this.$store.state.settings;
    },
    getUserSettings() {
      return this.$store.state.userSettings;
    },
    changeTheme(theme) {
      if (!document.documentElement.classList.contains("contrast")) {
        Theme.set(theme);
      }
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
    themeName() {
      return this.getUserSettings().appTheme;
    },
  },
};
</script>
