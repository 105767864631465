<template>
  <div class="refreshComponent" :class="theme" :style="{ top: top + 'px' }">
    <div class="refreshComponent__icon" :class="loading && 'rotating'">
      <font-awesome-icon icon="fa-solid fa-rotate-right" />
    </div>
    <span>Odśwież</span>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "RefreshComponent",
  props: ["top", "loading"],
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
