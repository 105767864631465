<template>
  <section class="wasteSection" :class="theme" @click="$router.push(route)">
    <div class="wasteSection__text">
      <div class="wasteSection__title">{{ title }}</div>
      <div class="wasteSection__description">{{ description }}</div>
    </div>
    <ButtonComponent text="Zobacz" icon="chevron-right" icon-position="right" />
  </section>
</template>

<script>
import "./style.scss";
import ButtonComponent from "@/components/Button/component.vue";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "WasteSection",
  components: {
    ButtonComponent,
  },
  props: {
    title: String,
    description: String,
    route: String,
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
