import DB from "@/assets/js/DB.js";

class Online {
  static async Check() {
    if (!window.navigator.onLine) return false;
    try {
      const response = await DB.get("/online");
      if (response.status === "success") return true;
    } catch (error) {
      return false;
    }
    return false;
  }
}

export default Online;
