import { Preferences } from "@capacitor/preferences";
import Notifications from "@/assets/js/Notifications.js";
import notifications from "@/store/modules/notifications";
import modals from "@/store/modules/modals";
import { createStore } from "vuex";

export default createStore({
  state: {
    name: "zk_store",
    core: 1,
    version: 7,
    settings: {
      appVersion: parseInt(process.env.VUE_APP_VERSION),
      appVersionText: process.env.VUE_APP_VERSION_TEXT,
      appAPI: process.env.VUE_APP_API,
      appAPISiteHeader: process.env.VUE_APP_SITE_HEADER,
      FCMToken: null,
      appNameStore: process.env.VUE_APP_NAME_STORE,
      appAuthor: "Digital Holding Sp. z o.o.",
      landingPage: process.env.VUE_APP_LANDING_PAGE,
      appLogo: "logo.svg",
      splashLogo: "logo.svg",
      appBackground: "bgrnd.png",
      appDefaultNewsImage: "news.png",
      appUpTitle: process.env.VUE_APP_UPTITLE,
      appTitle: process.env.VUE_APP_TITLE,
      appOnline: true,
      pushNotificationsAvailable: true,
      appExtraMenu: {
        wcag: true,
        eu: false,
      },
      appModules: [
        {
          name: "Pulpit",
          route: "/waste",
          icon: "fa-solid fa-house",
          color: "default",
          active: true,
        },
        {
          name: "Ogłoszenia",
          route: "/announcements",
          icon: "fa-solid fa-bullhorn",
          color: "default",
          active: true,
        },
        {
          name: "Aktualności",
          route: "/news",
          icon: "fa-regular fa-newspaper",
          color: "default",
          active: true,
        },
        {
          name: "Płatności",
          route: "/payment",
          icon: "fa-regular fa-credit-card",
          color: "default",
          active: true,
        },
        {
          name: "Ustawienia",
          route: "/settings",
          icon: "fa-solid fa-sliders",
          color: "default",
          active: true,
        },
      ],
      themes: [
        {
          name: "domyślny systemu",
          value: "default",
        },
        {
          name: "jasny",
          value: "light",
        },
        {
          name: "ciemny",
          value: "dark",
        },
      ],
      notificationTerms: [
        {
          name: "wybierz z listy",
          value: false,
        },
        {
          name: "dzień wywozu",
          value: 0,
        },
        {
          name: "dzień wcześniej",
          value: -1,
        },
        {
          name: "dwa dni wcześniej",
          value: -2,
        },
        {
          name: "trzy dni wcześniej",
          value: -3,
        },
      ],
    },
    userSettings: {
      favoriteDistrict: false,
      notifications: {
        newsNotification: false,
        announcementNotification: false,
        paymentNotification: false,
        waste: [],
      },
      appTheme: "default",
      tmpTheme: false,
      districtsLastEdited: new Date().toISOString().split("T")[0],
    },
    articles: {},
    files: [],
    waste: {},
    districts: {},
    fractions: {},
    contact: {},
    payment: {},
    point: {},
    privacy: {},
    accessibility: {},
    links: {},
  },
  getters: {},
  mutations: {
    changeSettings(state, object) {
      state.settings[object.prop] = object.val;
    },
    changeNotificationsSettings(state, object) {
      state.userSettings.notifications[object.prop] = object.val;
    },
    changeUserSettings(state, object) {
      state.userSettings[object.prop] = object.val;
    },
    setFCMToken(state, object) {
      state.settings.FCMToken = object.token;
    },
    setDistrictsLastEdited(state, object) {
        state.userSettings.districtsLastEdited = object.districtsLastEdited;
    },
  },
  actions: {
    async saveState() {
      await Preferences.set({
        key: this.state.name,
        value: JSON.stringify(this.state),
      });
    },
    async fullClearAppCache() {
      await Preferences.removeOld();

      return Preferences.clear();
    },
    async loadState({ commit, dispatch }) {
      let stateFromDB = await Preferences.get({ key: this.state.name });
      if (stateFromDB.value) {
        const loadedState = JSON.parse(stateFromDB.value);
        const copyState = JSON.parse(JSON.stringify(this.state));
        const core = this.state.core;
        const settings = this.state.settings;
        const version = this.state.version;

        Object.assign(this.state, loadedState);

        if (version > loadedState.version) {
          Object.assign(this.state.settings, settings);
          this.state.version = version;
        }

        if (core > loadedState.core) {
          Object.assign(this.state, copyState);
          this.state.core = core;

          await dispatch("fullClearAppCache");
        }
      }

      await Preferences.set({
        key: this.state.name,
        value: JSON.stringify(this.state),
      });
    },
  },
  modules: {
    notifications,
    modals,
  },
});
