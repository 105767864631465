<template>
  <div class="view announcement-container" :class="theme">
    <div v-if="loading" class="view__loader" key="loader">
      <LoaderComponent :display="loading" />
    </div>
    <span v-if="data && data.date" class="date">{{
      formatDate(data.date)
    }}</span>
    <h1 v-if="data && data.title" class="view__heading">{{ data.title }}</h1>
    <div class="announcement">
      <div
        v-if="data && data.body"
        class="announcement__content article"
        :class="theme"
        v-html="data.body"
      ></div>
    </div>
  </div>
</template>

<script>
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data";
import LoaderComponent from "@/components/Loader/component.vue";

export default {
  name: "AnnouncementView",
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  components: { LoaderComponent },
  computed: {
    theme() {
      return Theme.get();
    },
    routeId() {
      return this.$route.params.id;
    },
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp.length) {
        return "";
      }

      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    async getNotification() {
      this.loading = true;
      this.data = await Data.getNotification(this.$route.params.id);
      this.loading = false;
    },
  },
  watch: {
    routeId() {
      this.getNotification();
    },
  },
  created() {
    this.getNotification();
  },
};
</script>

<style scoped lang="scss">
.announcement-container {
  &.dark {
    .date {
      color: var(--color-light);
    }
  }

  .date {
    display: flex;
    font-size: var(--font-size-12);
    color: var(--color-medium);
    opacity: 0.5;
    font-weight: 600;
    margin-top: var(--gap-large);
    padding: 0 var(--gap-medium);
  }
}
</style>
