class DateExtensions {
  static addDays(date, days) {
    return new Date(date.setDate(date.getDate() + days));
  }

  static setHour(date, hour) {
    return new Date(date.setHours(hour, 0, 0));
  }
}

export default DateExtensions;
