<template>
  <div class="view payment-view" :class="theme">
    <h1 class="view__heading">{{ payment.title ? payment.title : 'Płatności' }}</h1>
    <div class="settings">
      <transition name="slideLong">
        <PopupComponent
            v-if="popupData.display"
            :color="popupData.color"
            :text="popupData.text"
            v-model="popupData.display"
            :displayTime="3"
            size="large"
            position="top"
        />
      </transition>
      <SettingsPosition
          v-if="getSettings().pushNotificationsAvailable"
          label="Przypomnienie o płatnościach"
          v-model="pushNotifications.payment"
          :checkbox="true"
          @setCheckboxVal="(value) => toggleNotification('payment', value)"
      />
    </div>
    <div class="payment">
      <div
        v-if="payment"
        class="payment__content article"
        :class="theme"
        v-html="payment.content"
      ></div>
    </div>
  </div>
</template>

<script>
import Theme from "@/assets/js/Theme.js";
import SettingsPosition from "@/components/SettingsPosition/component.vue";
import Notifications from "@/assets/js/Notifications";
import Data from "@/assets/js/Data";
import PopupComponent from "@/components/Popup/component.vue";

export default {
  name: "PaymentView",
  components: {PopupComponent, SettingsPosition},
  data() {
    return {
      popupData: {
        display: false,
        color: "",
        text: "",
      },
      pushNotifications: {
        payment: this.notifications().paymentNotification,
      },
    };
  },
  computed: {
    theme() {
      return Theme.get();
    },
    payment() {
      return this.$store.state.payment?.data
        ? this.$store.state.payment.data[0]
        : null;
    },
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
    notifications() {
      return Notifications.getAllNotifications();
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
    setNotificationPreferences() {
      Data.setNotificationPreferences()
          .then(() => {})
          .catch(() => {
            this.showPopup("error", "Nie udało się ustawić powiadomienia!");
          });
    },
    async toggleNotification(notificationKey, value) {
      this.pushNotifications[notificationKey] =
          await Notifications.setPushState(
              `${notificationKey}Notification`,
              value
          );

      this.setNotificationPreferences();

      if (!this.pushNotifications[notificationKey] && value) {
        this.showPopup(
            "error",
            "Nie udało się włączyć powiadomień. Sprawdź ustawienia telefonu i włącz powiadomienia dla aplikacji."
        );
      }
    },
  }
};
</script>

<style scoped lang="scss">
.payment-view {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  width: 100%;
}

</style>
