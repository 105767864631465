<template>
  <div class="infoView" :class="theme">
    <h3>Informacje o aplikacji</h3>
    <div class="infoView__position">
      <div class="infoView__value">{{ getSettings().appNameStore }}</div>
      <div class="infoView__name">Nazwa aplikacji</div>
    </div>
    <div class="infoView__position" @click="showHiddenSection">
      <div class="infoView__value">{{ getSettings().appVersionText }}</div>
      <div class="infoView__name">Wersja</div>
    </div>
    <div class="infoView__position">
      <div class="infoView__value">{{ getSettings().appAuthor }}</div>
      <div class="infoView__name">Twórca</div>
    </div>
    <ButtonComponent
      text="O aplikacji"
      icon="circle-info"
      icon-position="left"
      @click="page()"
    />
    <div class="infoView__hidden" v-if="!hidden">
      <div>Wersja store: {{ $store.state.version }}</div>
      <div>Wersja aplikacji: {{ getSettings().appVersion }}</div>
      <div>Schemat kolorów: {{ getUserSettings().appTheme }}</div>
      <div>Aktualności: {{ $store.state.articles.data?.length || 0 }}</div>
      <div>Kategorie dokumentów: {{ $store.state.files.length }}</div>
      <div>Terminy w kalendarzu: {{ $store.state.waste.data.length }}</div>
      <div>Frakcje: {{ $store.state.fractions.data.length }}</div>
      <div>
        Powiadomienia w ustawieniach użytkownika:
        {{ getUserNotificationsCount() }}
      </div>
      <div>Ilość generowanych powiadomień: {{ getGeneratedCount() }}</div>
      <div>Ustawione oczekujące powiadomienia: {{ pendingCount }}</div>
      <div class="infoView__button" @click="setTestNotification">
        {{ buttonText }}
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import Notifications from "@/assets/js/Notifications.js";
import { LocalNotifications } from "@capacitor/local-notifications";
import ButtonComponent from "@/components/Button/component.vue";

export default {
  name: "InfoView",
  components: {
    ButtonComponent,
  },
  data() {
    return {
      hidden: true,
      pendingCount: 0,
      taps: 0,
      timeout: false,
      buttonText: "Ustaw testowe powiadomienie (za minutę)",
      saving: false,
    };
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
    getUserSettings() {
      return this.$store.state.userSettings;
    },
    getUserNotificationsCount() {
      return Notifications.getAllNotifications().waste.length;
    },
    page() {
      window.open(this.getSettings().landingPage, "_blank");
    },
    async checkPendingCount() {
      const pending = await Notifications.getPendingNotifications();
      this.pendingCount = pending.notifications.length;
    },
    getGeneratedCount() {
      return Notifications.generate(false).length;
    },
    showHiddenSection() {
      this.taps++;
      if (this.taps === 10) this.hidden = false;
      if (!this.timeout) {
        this.timeout = true;
        setTimeout(() => {
          this.taps = 0;
          this.timeout = false;
        }, 3000);
      }
    },
    async setTestNotification() {
      if (!this.saving) {
        this.saving = true;
        const perms = await Notifications.checkPerms();
        if (perms) {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: "Dzisiaj zaplanowany odbiór odpadów",
                body: `Dzisiaj (${
                  new Date().toISOString().split("T")[0]
                }) zostaną odebrane odpady frakcji TEST z rejonu Rejon 1: Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque nemo accusamus eveniet tempore impedit sequi reiciendis quaerat quas placeat quia omnis quae veritatis nihil, nisi adipisci alias ducimus cum vero voluptatum autem officia porro dolorem mollitia magnam? Iure magni, quis repellat, laudantium quia hic dignissimos error rerum illum officia odit`,
                largeBody: `Dzisiaj (${
                  new Date().toISOString().split("T")[0]
                }) zostaną odebrane odpady frakcji TEST z rejonu Rejon 1: Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque nemo accusamus eveniet tempore impedit sequi reiciendis quaerat quas placeat quia omnis quae veritatis nihil, nisi adipisci alias ducimus cum vero voluptatum autem officia porro dolorem mollitia magnam? Iure magni, quis repellat, laudantium quia hic dignissimos error rerum illum officia odit`,
                id: Math.floor((Date.now() / 100000) * Math.random()),
                schedule: {
                  at: new Date(new Date().getTime() + 60000),
                  allowWhileIdle: true,
                },
                sound: null,
                attachments: null,
                actionTypeId: "",
                extra: { district: 1 },
              },
            ],
          });
          this.checkPendingCount();
          this.buttonText = "Ustawione!";
          setTimeout(() => {
            this.buttonText = "Ustaw testowe powiadomienie (za minutę)";
            this.saving = false;
          }, 2000);
        }
      }
    },
  },
  created() {
    this.checkPendingCount();
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
