<template>
  <div class="loaderComponent" :class="[theme, color]" v-if="display">
    <div class="loaderComponent__spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="loaderComponent__message">{{ message }}</div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "LoaderComponent",
  props: {
    display: Boolean,
    message: String,
    color: String,
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
