<template>
  <transition name="fadeBlurScale">
    <div class="splashScreen" :class="theme" v-if="display">
      <div class="splashScreen__overlay"></div>
      <img
        alt="background"
        :src="'/img/background/' + getSettings().appBackground"
        class="splashScreen__bgrnd"
      />
      <img
        alt="logo"
        :src="'/img/logo/' + getSettings().splashLogo"
        class="splashScreen__img"
      />
      <div class="splashScreen__title">{{ getSettings().appUpTitle }}</div>
      <div class="splashScreen__title">{{ getSettings().appTitle }}</div>
      <LoaderComponent
        :display="loader.display"
        :message="loader.message"
        :color="loader.color"
      />
      <div class="splashScreen__offline" v-if="offline">
        <div class="splashScreen__offline-icon">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
        </div>
        <div class="splashScreen__offline-title">Jesteś w trybie offline!</div>
        <span
          >Przy pierwszym uruchomieniu aplikacja wymaga aktywnego połączenia
          internetowego. Sprawdź połączenie z siecią i spróbuj ponownie.</span
        >
        <div class="splashScreen__button" @click="refresh">
          Spróbuj ponownie
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import "./style.scss";
import Data from "@/assets/js/Data.js";
import Online from "@/assets/js/Online.js";
import LoaderComponent from "@/components/Loader/component.vue";
import Theme from "@/assets/js/Theme.js";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

export default {
  name: "SplashScreen",
  components: {
    LoaderComponent,
  },
  data() {
    return {
      loader: {
        display: true,
        message: "Ładowanie...",
        color: "lightSpinner",
      },
      offline: false,
      display: true,
      refreshing: false,
    };
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
    refresh() {
      this.$router.go(0);
    },
    setBackground(theme) {
      if (theme === "dark") {
        document.documentElement.classList.add("dark");
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", "#222");
      }
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() === "android") {
      StatusBar.setOverlaysWebView({ overlay: true });
    }
    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    await this.$store.dispatch("loadState");
    this.setBackground(this.theme);
    const isOnline = await Online.Check();
    if (!isOnline) {
      this.offline = true;
      this.loader.display = false;
      if (
        this.$store.state.districts.data &&
        this.$store.state.districts.data.length &&
        this.$store.state.fractions.data &&
        this.$store.state.fractions.data.length
      ) {
        this.display = false;
        this.$emit("loaded", false);
      }
      return;
    }

    try {
      await Data.load();
    } catch (e) {
      console.error("Error", e);
    } finally {
      this.display = false;
    }

    this.$emit("loaded", true);
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
