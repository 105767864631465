<template>
  <div class="inlineNotification" :class="theme">
    <div class="inlineNotification__icons">
      <font-awesome-icon icon="fa-solid fa-xmark" />
      <font-awesome-icon icon="fa-solid fa-minus" />
      <div class="inlineNotification__icon">
        <font-awesome-icon :icon="icon" />
      </div>
      <font-awesome-icon icon="fa-solid fa-minus" />
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </div>
    <div class="inlineNotification__text">
      {{ text }}
    </div>
    <div class="inlineNotification__button" v-if="action" @click="reload">
      <font-awesome-icon icon="fa-solid fa-rotate-right" />
      <div>Odśwież</div>
    </div>
    <transition name="fade">
      <LoaderComponent
        :display="loader.display"
        :message="loader.message"
        :color="loader.color"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data.js";
import LoaderComponent from "@/components/Loader/component.vue";

export default {
  name: "InlineNotification",
  props: ["icon", "text", "action"],
  data() {
    return {
      loader: {
        display: false,
        message: "Ładowanie...",
        color: "",
      },
    };
  },
  components: {
    LoaderComponent,
  },
  methods: {
    async reload() {
      this.loader.color =
        this.theme === "light" ? "darkSpinner" : "lightSpinner";
      this.loader.display = true;
      await Data.load(this.action);
      this.loader.display = false;
      this.$emit("reload");
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
