<template>
  <div class="privacyView" :class="theme">
    <h3>Polityka prywatności</h3>
    <div v-if="privacyPolicyLink" class="privacyView__content">
      <ExternalLinkButton :href="privacyPolicyLink" text="Zobacz" icon="eye" />
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import ExternalLinkButton from "@/components/ExternalLinkButton.vue";

export default {
  name: "PrivacyView",
  components: { ExternalLinkButton },
  computed: {
    theme() {
      return Theme.get();
    },
    privacyPolicyLink() {
      return this.$store.state.links.privacy_policy
        ? this.$store.state.links.privacy_policy
        : null;
    },
  },
};
</script>
