<template>
  <div class="notificationsView" :class="theme" v-if="notificationsState">
    <h3>Powiadomienia push</h3>
    <!--    <SettingsPosition-->
    <!--      v-if="getSettings().pushNotificationsAvailable"-->
    <!--      label="Nowe aktualności"-->
    <!--      v-model="pushNotifications.news"-->
    <!--      :checkbox="true"-->
    <!--      @setCheckboxVal="(value) => toggleNotification('news', value)"-->
    <!--    />-->
    <SettingsPosition
      v-if="getSettings().pushNotificationsAvailable"
      label="Nowe ogłoszenia"
      v-model="pushNotifications.announcement"
      :checkbox="true"
      @setCheckboxVal="(value) => toggleNotification('announcement', value)"
    />
    <SettingsPosition
        v-if="getSettings().pushNotificationsAvailable"
        label="Przypomnienie o płatnościach"
        v-model="pushNotifications.payment"
        :checkbox="true"
        @setCheckboxVal="(value) => toggleNotification('payment', value)"
    />
    <h3 class="notificationsView__title">Powiadomienia z harmonogramu</h3>
    <div
      class="notificationsView__section"
      v-if="!notifications().waste.length"
    >
      <h3>Brak ustawionych powiadomień!</h3>
      <div class="notificationsView__message">
        Aby dodać powiadomienia przejdź do sekcji Harmonogram gminy w zakładce
        Odpady i wybierz rejon, z którego chcesz otrzymywać powiadomienia.
      </div>
    </div>
    <div class="notificationsView__list" v-else>
      <div
        class="notificationsView__district"
        v-for="district in districts()"
        :key="district.id"
      >
        <div
          class="notificationsView__district--name"
          v-html="district.name"
        ></div>
        <div class="notificationsView__district--notifications">
          <transition-group name="fade">
            <NotificationComponent
              v-for="item in getActiveNotifications(district.id)"
              :notification="item"
              :key="item.id"
              @edit="editNotification"
            />
          </transition-group>
        </div>
      </div>
    </div>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="3"
        size="large"
        position="top"
      />
    </transition>
    <transition name="fadeBlurScale">
      <NotificationModal
        :district="notification.district"
        :notification="notification"
        @saved="notificationSaved"
        v-if="notificationmodal"
        @close="modalClose"
        :backdrop-close="true"
        :close-button="true"
      />
    </transition>
  </div>
  <div class="notificationsView" :class="theme" v-else>
    <h3>Ustawienia powiadomień</h3>
    <div
      class="notificationsView__section"
      v-if="!notifications().waste.length"
    >
      <h3>Nie można włączyć powiadomień!</h3>
      <div class="notificationsView__message">
        Twoje urządzenie nie obsługuje w tej chwili powiadomień. Zaktualizuj
        system do najnowszej wersji i spróbuj ponownie.
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import SettingsPosition from "@/components/SettingsPosition/component.vue";
import NotificationModal from "@/components/NotificationModal/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import NotificationComponent from "@/components/Notification/component.vue";
import Theme from "@/assets/js/Theme.js";
import Data from "@/assets/js/Data";
import Notifications from "@/assets/js/Notifications.js";

export default {
  name: "NotificationsView",
  components: {
    SettingsPosition,
    NotificationModal,
    PopupComponent,
    NotificationComponent,
  },
  data() {
    return {
      notificationmodal: false,
      notification: false,
      popupData: {
        display: false,
        color: "",
        text: "",
      },
      notificationsState: true,
      pushNotifications: {
        news: this.notifications().newsNotification,
        announcement: this.notifications().announcementNotification,
        payment: this.notifications().paymentNotification,
      },
    };
  },
  methods: {
    districts() {
      const districts = [];
      this.$store.state.districts.data.forEach((district) => {
        const descriptionPart = district.description
          ? this.formatString(district.description)
          : "";
        if (this.getActiveNotifications(district.id).length)
          districts.push({
            name: "<span>" + district.name + "</span><hr>" + descriptionPart,
            id: district.id,
          });
      });
      return districts;
    },
    formatString(input, length) {
      const words = input.split(" ");
      if (words.length > length) {
        return words.slice(0, length).join(" ") + "...";
      }
      return input;
    },
    getActiveNotifications(district) {
      return Notifications.getActiveNotifications(district);
    },
    async toggleNotification(notificationKey, value) {
      this.pushNotifications[notificationKey] =
        await Notifications.setPushState(
          `${notificationKey}Notification`,
          value
        );

      this.setNotificationPreferences();

      if (!this.pushNotifications[notificationKey] && value) {
        this.showPopup(
          "error",
          "Nie udało się włączyć powiadomień. Sprawdź ustawienia telefonu i włącz powiadomienia dla aplikacji."
        );
      }
    },
    setNotificationPreferences() {
      Data.setNotificationPreferences()
        .then((res) => {
          if (res) {
            this.showPopup("success", "Powiadomienie zostało zapisane.");
          }
        })
        .catch(() => {
          this.showPopup("error", "Nie udało się ustawić powiadomienia!");
        });
    },
    getSettings() {
      return this.$store.state.settings;
    },
    notificationSaved() {
      this.showPopup("success", "Powiadomienie zostało zapisane.");
    },
    showPopup(color, text) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
    },
    editNotification(notification) {
      this.notification = notification;
      this.notificationmodal = true;
    },
    modalClose() {
      this.notification = false;
      this.notificationmodal = false;
    },
    notifications() {
      return Notifications.getAllNotifications();
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
