import { createRouterScroller } from "vue-router-better-scroller";

const routerScroller = createRouterScroller({
  selectors: {
    window: true,
    body: true,
    ".scrollable": true,
  },
});

export default routerScroller;
