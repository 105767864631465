<template>
  <div class="scheduleView" :class="theme">
    <transition name="fade">
      <div class="scheduleView__favorite" v-if="district">
        <SelectList
          label="Rodzaj odpadów"
          :expandable="true"
          :items="fractions()"
          v-model="fraction"
          @select="dates = parseData()"
        />
        <div class="scheduleView__controls special">
          <div class="scheduleView__control" @click="district = false">
            <font-awesome-icon icon="fa-solid fa-arrow-right-arrow-left" />
          </div>
          <div
            class="scheduleView__control"
            :class="{ checked: notifications }"
            @click="notificationmodal = true"
          >
            <font-awesome-icon
              :icon="notifications ? 'fa-solid fa-bell' : 'fa-regular fa-bell'"
            />
          </div>
        </div>
        <CalendarComponent v-model:display="district" :dates="dates" />
        <div class="scheduleView__additionalInformation">
          Pojemniki i worki należy wystawiać do godz. 6:00 rano w dniu wywozu.
        </div>
        <div class="scheduleView__district">
          <h3>Rejon:</h3>
          <div>
            <span class="bold-text" v-text="getNameDistrict(district)"></span>
            <span v-text="getDescriptionDistrict(district)"></span>
          </div>
        </div>
        <FractionsLegend />
      </div>
      <div class="scheduleView__selectFavorite" v-else>
        <div class="scheduleView__alert" v-if="!favouriteDistrictId">
          Żaden rejon nie został oznaczony jako ulubiony!
        </div>
        <div class="scheduleView__message">
          Wybierz rejon z poniższej listy i zapisz go.
        </div>
        <SelectList
          label="Wybierz rejon"
          :expandable="true"
          :items="districts()"
          v-model="selectedDistrict"
          :isSelectedOptionTextBold="true"
        />
        <div class="scheduleView__buttons">
          <ButtonComponent
            text="Anuluj"
            :secondary="true"
            v-if="favouriteDistrictId"
            @click="district = favouriteDistrictId"
          />
          <ButtonComponent
            text="Zapisz rejon"
            icon="save"
            icon-position="left"
            @click="saveDistrict"
          />
        </div>
      </div>
    </transition>
    <transition name="fadeBlurScale">
      <NotificationModal
        :district="district"
        @saved="notificationSaved"
        v-if="notificationmodal"
        @close="notificationmodal = false"
        :backdrop-close="true"
        :close-button="true"
        :list="true"
      />
    </transition>
    <transition name="slideLong">
      <PopupComponent
        v-if="popupData.display"
        :color="popupData.color"
        :text="popupData.text"
        v-model="popupData.display"
        :displayTime="popupData.displayTime"
        size="large"
        position="top"
      />
    </transition>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";
import SelectList from "@/components/SelectList/component.vue";
import CalendarComponent from "@/components/Calendar/component.vue";
import FractionsLegend from "@/components/FractionsLegend/component.vue";
import ButtonComponent from "@/components/Button/component.vue";
import NotificationModal from "@/components/NotificationModal/component.vue";
import PopupComponent from "@/components/Popup/component.vue";
import Notifications from "@/assets/js/Notifications.js";

export default {
  name: "MyScheduleView",
  data() {
    return {
      selectedDistrict: false,
      district: this.$store.state.userSettings.favoriteDistrict,
      fraction: false,
      notificationmodal: false,
      dates: [],
      calendarKey: 0,
      popupData: {
        display: false,
        color: "",
        text: "",
        displayTime: 3,
      },
    };
  },
  components: {
    SelectList,
    CalendarComponent,
    FractionsLegend,
    ButtonComponent,
    NotificationModal,
    PopupComponent,
  },
  methods: {
    districts() {
      const districts = [{ name: "wybierz z listy", value: false }];

      if (this.$store.state.districts.data) {
        this.$store.state.districts.data.forEach((district) => {
          const descriptionPart = district.description
            ? this.formatString(district.description)
            : "";
          const districtName = district.description
            ? district.name + " : "
            : district.name;

          districts.push({
            name: districtName,
            subName: descriptionPart,
            value: district.id,
          });
        });
      }
      return districts;
    },
    getNameDistrict(id) {
      const selectedDistrict = this.getFavouriteDistrict(id);

      return selectedDistrict.description
        ? selectedDistrict.name + " : "
        : selectedDistrict.name;
    },
    getDescriptionDistrict(id) {
      const selectedDistrict = this.getFavouriteDistrict(id);

      return selectedDistrict.description
        ? this.formatString(selectedDistrict.description)
        : "";
    },
    getFavouriteDistrict(id) {
      return this.$store.state.districts?.data
        ? this.$store.state.districts.data.find((item) => item.id === id)
        : null;
    },
    fractions() {
      const fractions = [{ name: "wszystkie", value: false }];
      this.$store.state.fractions.data.forEach((fraction) => {
        fractions.push({ name: fraction.name, value: fraction.id });
      });
      return fractions;
    },
    formatString(input, length) {
      const words = input.split(" ");
      if (words.length > length) {
        return words.slice(0, length).join(" ") + "...";
      }
      return input;
    },
    parseData() {
      let data = [];
      if (!this.district || !this.$store.state.waste.data) return data;
      data = this.$store.state.waste.data.filter(
        (item) => item.id_district === this.district
      );
      if (this.fraction)
        data = data.filter((item) => item.id_fraction === this.fraction);
      return data;
    },
    getActiveNotifications() {
      return Notifications.getActiveNotifications(this.district);
    },
    notificationSaved() {
      this.showPopup("success", "Powiadomienie zostało zapisane.");
    },
    showPopup(color, text, displayTime = 3) {
      this.popupData.display = true;
      this.popupData.color = color;
      this.popupData.text = text;
      this.popupData.displayTime = displayTime;
    },
    getSettings() {
      return this.$store.state.settings;
    },
    saveDistrict() {
      this.district = this.selectedDistrict;
      if (this.district) {
        this.$store.state.userSettings.favoriteDistrict = this.district;
        this.$store.dispatch("saveState");
        this.dates = this.parseData();
      }
    },
    deleteFavoriteDistrict() {
      this.$store.state.userSettings.favoriteDistrict = false;
      this.district = false;
      setTimeout(() => {
        this.showPopup(
          "warning",
          "Pojawiła się zmiana w rejonach! Ustaw ulubiony rejon ponownie!",
          10
        );
      }, 300);
    },
    validateFavoriteDistrictExist() {
      if (this.favouriteDistrictId) {
        const district = this.getFavouriteDistrict(this.favouriteDistrictId);
        if (!district) {
          this.deleteFavoriteDistrict();
        }
      }
    },
  },
  mounted() {
    this.dates = this.parseData();

    this.validateFavoriteDistrictExist();
  },
  computed: {
    theme() {
      return Theme.get();
    },
    notifications() {
      return this.getActiveNotifications().length;
    },
    favouriteDistrictId() {
      return this.$store.state.userSettings.favoriteDistrict;
    },
  },
};
</script>
