<template>
  <div class="announcementComponent" :class="theme">
    <div class="announcementComponent__text">
      <div class="announcementComponent__date">{{ formatDate(data.date) }}</div>
      <div class="announcementComponent__title">{{ data.title }}</div>
      <div class="announcementComponent__description">{{ data.body }}</div>
      <font-awesome-icon
        class="announcementComponent__icon"
        icon="fa-solid fa-chevron-right"
      />
    </div>
  </div>
</template>

<script>
import Theme from "@/assets/js/Theme.js";

export default {
  name: "AnnouncementComponent",
  props: {
    data: Object,
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    getSettings() {
      return this.$store.state.settings;
    },
  },
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>

<style lang="scss" scoped>
.announcementComponent {
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: var(--shadow);
  position: relative;

  & &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: var(--font-size-16);
  }

  & &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--gap-medium) 40px var(--gap-medium) var(--gap-medium);
  }

  & &__title {
    color: var(--color-dark);
    font-weight: 500;
    font-size: var(--font-size-14);
  }

  & &__date {
    font-size: var(--font-size-12);
    color: var(--color-medium);
    opacity: 0.5;
    font-weight: 600;
    margin-bottom: var(--gap-small);
  }

  & &__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: var(--gap-small);
    font-size: var(--font-size-12);
    color: var(--color-medium);
  }

  &.dark {
    background-color: var(--color-dark);
  }

  &#{&}.dark & {
    &__icon {
      color: var(--color-light);
    }

    &__date {
      color: var(--color-light);
    }

    &__title {
      color: var(--color-light);
    }

    &__description {
      color: var(--color-light);
    }
  }
}
</style>
