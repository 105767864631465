<template>
  <div
    class="calendarComponent__backdrop"
    v-if="display"
    :class="{ modal: datepicker }"
    @click="backdropClick"
    @touchstart.passive="touchStart"
    @touchend.passive="touchEnd"
  >
    <div class="calendarComponent" :class="theme" @click.stop>
      <div class="calendarComponent__header">
        <div class="calendarComponent__control" @click.stop="monthPrev">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </div>
        <div class="calendarComponent__monthYear">
          {{ monthYearString }}
        </div>
        <div class="calendarComponent__control" @click.stop="monthNext">
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </div>
      </div>
      <div class="calendarComponent__body">
        <div class="calendarComponent__body-header">
          <div>Pon</div>
          <div>Wt</div>
          <div>Śr</div>
          <div>Czw</div>
          <div>Pt</div>
          <div>Sob</div>
          <div>Nie</div>
        </div>
        <div class="calendarComponent__body-days">
          <div class="inactive" v-for="day in daysPrevMonth" :key="day">
            {{ day }}
          </div>
          <div
            v-for="day in daysCurrMonth"
            :key="day"
            :class="{
              current:
                day === currDay &&
                month === new Date().getMonth() &&
                year === new Date().getFullYear(),
              waste: parseData(day).length,
            }"
            @click.stop="dateSelect(day)"
          >
            {{ day }}
            <div class="fractions">
              <div
                v-for="fraction in parseData(day)"
                :style="{ 'background-color': fraction.color }"
                :key="fraction.id"
              ></div>
            </div>
          </div>
          <div class="inactive" v-for="day in daysNextMonth" :key="day">
            {{ day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "CalendarComponent",
  data() {
    return {
      monthYearString: new Date().toLocaleDateString("pl-PL", {
        month: "long",
        year: "numeric",
      }),
      month: new Date().getMonth(),
      currDay: new Date().getDate(),
      year: new Date().getFullYear(),
      daysPrevMonth: this.generatePrevMonth(),
      daysCurrMonth: this.generateCurrMonth(),
      daysNextMonth: this.generateNextMonth(),
      swipe: false,
    };
  },
  props: ["date", "datepicker", "dates", "display"],
  methods: {
    lastDayOfCurrMonth() {
      return new Date(this.year, this.month + 1, 0).getDate();
    },
    lastDayOfPrevMonth() {
      return new Date(this.year, this.month, 0).getDate();
    },
    firstDayOfCurrMonth() {
      return new Date(this.year, this.month, 1).getDay();
    },
    lastWeekDayOfCurrMonth() {
      return new Date(
        this.year,
        this.month,
        this.lastDayOfCurrMonth()
      ).getDay();
    },
    generatePrevMonth() {
      const startValue =
        this.firstDayOfCurrMonth() === 0 ? 6 : this.firstDayOfCurrMonth() - 1;
      const ret = [];
      for (let i = startValue; i > 0; i--) {
        ret.push(this.lastDayOfPrevMonth() - i + 1);
      }
      return ret;
    },
    generateCurrMonth() {
      const ret = [];
      for (let i = 1; i <= this.lastDayOfCurrMonth(); i++) {
        ret.push(i);
      }
      return ret;
    },
    generateNextMonth() {
      const ret = [];
      if (this.lastWeekDayOfCurrMonth() !== 0) {
        for (let i = this.lastWeekDayOfCurrMonth(); i < 7; i++) {
          ret.push(i - this.lastWeekDayOfCurrMonth() + 1);
        }
      }
      return ret;
    },
    monthPrev() {
      this.month--;
      this.refreshCalendar();
    },
    monthNext() {
      this.month++;
      this.refreshCalendar();
    },
    refreshCalendar() {
      if (this.month < 0 || this.month > 11) {
        const date = new Date(this.year, this.month);
        this.year = date.getFullYear();
        this.month = date.getMonth();
      }
      this.daysPrevMonth = this.generatePrevMonth();
      this.daysCurrMonth = this.generateCurrMonth();
      this.daysNextMonth = this.generateNextMonth();
      this.monthYearString = new Date(this.year, this.month).toLocaleDateString(
        "pl-PL",
        { month: "long", year: "numeric" }
      );
    },
    dateSelect(day) {
      const date = new Date(this.year, this.month, day);
      this.$emit("update:date", date.toLocaleDateString());
      this.backdropClick();
    },
    backdropClick() {
      if (this.datepicker) {
        this.$emit("update:display", false);
      }
    },
    parseData(day) {
      const date = new Date(this.year, this.month, day).toLocaleDateString();
      if (!this.datepicker && this.monthWaste)
        return this.monthWaste.filter(
          (item) => date === new Date(item.date).toLocaleDateString()
        );
      return [];
    },
    touchStart(e) {
      e.stopPropagation();
      if (e.changedTouches.length !== 1) return;
      this.swipe = e.changedTouches[0].clientX;
    },
    touchEnd(e) {
      e.stopPropagation();
      if (e.changedTouches.length !== 1) return;
      const end = e.changedTouches[0].clientX;
      if (this.swipe + 30 < end) this.monthPrev();
      else if (this.swipe - 30 > end) this.monthNext();
    },
  },
  mounted() {
    this.refreshCalendar();
  },
  computed: {
    theme() {
      return Theme.get();
    },
    monthWaste() {
      const monthString =
        this.month + 1 < 10 ? "0" + (this.month + 1) : this.month + 1;
      return this.dates.filter((item) =>
        item.date.startsWith(this.year + "-" + monthString)
      );
    },
  },
};
</script>
