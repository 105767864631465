<template>
  <div class="view" :class="theme">
    <h1 class="view__heading">Informacje o finansowaniu</h1>
    <div class="eu" :class="theme">
      <div class="eu__banner">
        <img :src="`/img/other/eu_project_light.png`" class="eu__header" />
        <div class="eu__subtitle">
          Sfinansowano w ramach reakcji Unii na pandemię COVID-19
        </div>
      </div>
      <div class="eu__section">
        <span>Nazwa projektu:</span>
        Cyfrowa Gmina
      </div>
      <div class="eu__section">
        <span>Cel projektu:</span>
        Wsparcie rozwoju cyfrowego instytucji samorządowych oraz zwiększenie
        cyberbezpieczeństwa.
      </div>
      <div class="eu__section">
        <span>Opis koncepcji projektu:</span>
        Opis koncepcji realizacji grantu wpisuje się w cele V. osi „Rozwój
        cyfrowy JST oraz wzmocnienie cyfrowej odporności na zagrożenia -
        REACT-EU” programu operacyjnego Polska Cyfrowa na lata 2014-2020 oraz
        projektu „Cyfrowa Gmina”, jakim jest zwiększenie zdolności JST oraz
        podmiotów im podległych w zakresie realizacji usług publicznych na
        drodze teleinformatycznej, pracy i nauki zdalnej oraz wzmocnienie
        cyfrowe odporności na zagrożenia. Ponadto realizacja grantu przyczyni
        się do ograniczenia negatywnych skutków społecznych, gospodarczych oraz
        edukacyjnych wywołanych przez pandemię COVID-19 w latach 2020/21.
      </div>
      <div class="eu__section">
        <span>Wartość projektu:</span>
        1 286 192 994,70 zł
      </div>
      <div class="eu__section">
        <span>Wkład Funduszy Europejskich:</span>
        1 286 192 994,70 zł
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Theme from "@/assets/js/Theme.js";

export default {
  name: "EUView",
  computed: {
    theme() {
      return Theme.get();
    },
  },
};
</script>
