<template>
  <footer class="mainMenu" :class="theme">
    <MainMenuIcon
      v-for="module in activeModules"
      :key="module.route"
      :config="module"
    />
    <div
      class="mainMenu__indicator"
      :style="{
        width: 100 / activeModules.length + '%',
        left: activeElem.offsetLeft + 'px',
      }"
    ></div>
  </footer>
</template>

<script>
import "./style.scss";
import MainMenuIcon from "@/components/MainMenuIcon/component.vue";
import Theme from "@/assets/js/Theme.js";
import { Capacitor } from "@capacitor/core";

export default {
  name: "MainMenu",
  components: {
    MainMenuIcon,
  },
  data() {
    return {
      activeElem: false,
    };
  },
  methods: {
    getSettings() {
      return this.$store.state.settings;
    },
  },
  mounted() {
    this.activeElem = document
      .querySelector(".mainMenu")
      .querySelector(".active");
    if (Capacitor.getPlatform() === "ios") {
      document.querySelector(".mainMenu").style.height = "74px";
      document.querySelector(".mainMenu").style.paddingBottom = "10px";
      document.querySelector(".view").style.bottom = "74px";
    }
  },
  computed: {
    theme() {
      return Theme.get();
    },
    activeModules() {
      return this.getSettings().appModules.filter((module) => {
        return module.active;
      });
    },
  },
  watch: {
    $route(to, from) {
      // reset two variables when leaving news tab
      if (from.path.includes("news") && !to.path.includes("news")) {
        delete this.$store.loadedNews;
        delete this.$store.pageNews;
      }
      setTimeout(() => {
        this.activeElem = document
          .querySelector(".mainMenu")
          .querySelector(".active");
      }, 0);
    },
  },
};
</script>
